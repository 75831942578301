import React from 'react';
import List from '@mui/material/List';
import { NavLink } from 'react-router-dom';
import logo from 'src/Images/shreeDurga.png';
import { AppBar } from 'src/components/Layout/NavbarStyles';
import SidebarData from 'src/components/SidebarData';
import {
  Drawer,
  DrawerHeader,
  StyledOpenIconButton,
  StyledCloseIconButton,
  StyledImageBox,
  StyledListItemButton,
  StyledListItemIcon,
  IconLeft,
  IconRight,
  StyledListItem,
  StyledTypography,
} from 'src/components/Layout/SidebarStyles';
import Tooltip from '@mui/material/Tooltip';
import 'src/style/sidebar.css';
import Navbar from 'src/components/Navbar';
import { styled } from '@mui/system';

const OverlappingDrawer = styled(Drawer)(() => ({
  zIndex: 3,
}));

const OverlappingAppBar = styled(AppBar)(() => ({
  zIndex: 3,
}));

function Sidebar() {
  const [openSidebar, setOpenSidebar] = React.useState(false); //
  const handleDrawerOpen = () => {
    setOpenSidebar(true);
  };
  const handleDrawerClose = () => {
    setOpenSidebar(false);
  };

  const filteredSidebarData = SidebarData();

  return (
    <>
      <OverlappingAppBar position="fixed" open={openSidebar}>
        <Navbar />
      </OverlappingAppBar>
      <OverlappingDrawer variant="permanent" open={openSidebar}>
        <DrawerHeader className="sidebar-header" open={openSidebar}>
          <StyledImageBox open={openSidebar}>
            <img src={logo} alt="expound-logo" width={30} height={30} />
          </StyledImageBox>
          <StyledOpenIconButton onClick={handleDrawerOpen} open={openSidebar}>
            <IconRight />
          </StyledOpenIconButton>
          <StyledCloseIconButton onClick={handleDrawerClose} open={openSidebar}>
            <IconLeft />
          </StyledCloseIconButton>
        </DrawerHeader>
        <List className="listStyle">
          {filteredSidebarData.map((text) => (
            <Tooltip key={text.id} placement="left">
              <StyledListItem disablePadding className="sidebar-link-item">
                <NavLink
                  to={text?.url}
                  className={({ isActive }) =>
                    isActive ? 'active' : 'unactive'
                  }
                >
                  <StyledListItemButton
                    className="sidebar-button"
                    open={openSidebar}
                  >
                    <StyledListItemIcon open={openSidebar}>
                      {text?.icon}
                    </StyledListItemIcon>
                    <StyledTypography
                      className="sidebarText"
                      open={openSidebar}
                    >
                      {text?.name}
                    </StyledTypography>
                  </StyledListItemButton>
                </NavLink>
              </StyledListItem>
            </Tooltip>
          ))}
        </List>
      </OverlappingDrawer>
    </>
  );
}

export default Sidebar;
