import {
  CREATE_STAGE,
  CREATE_ORGANISATION,
  ORGANISATION_LIST,
  ASSIGN_STAGE,
  STAGES_LIST,
} from 'src/redux/Actions/Type';
const initialState = {
  tenantList: [],
  organisationList: [],
  createUser: {},
  createTenant: {},
  createStage: {},
  createOrganisation: {},
  assignStage: {},
  allUsers: [],
  updateProfile: {},
  stagesList: [],
};

const OrganisationReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case STAGES_LIST:
      return {
        ...state,
        stagesList: payload,
      };
    case ASSIGN_STAGE:
      return {
        ...state,
        assignStage: payload,
      };
    case ORGANISATION_LIST:
      return {
        ...state,
        organisationList: payload,
      };
    case CREATE_ORGANISATION:
      return {
        ...state,
        createOrganisation: payload,
      };
    case CREATE_STAGE:
      return {
        ...state,
        createStage: payload,
      };
    default:
      return state;
  }
};
export default OrganisationReducer;
