import React from 'react';
import {
  VscLayoutSidebarLeftOff,
  VscLayoutSidebarRightOff,
} from 'react-icons/vsc';
import { Row, Col } from 'react-bootstrap';

function ThreeColLayout({
  showHeader,
  showLeftSidebar,
  showMiddleContainer,
  showRightSidebar,
  leftSidebarContent,
  middleContainerContent,
  rightSidebarContent,
  headerContent,
  showFooter,
  onToggleLeftLayout,
  onToggleRightLayout,
  leftColSize,
  middleColSize,
  rightColSize,
}) {
  return (
    <div className="div-flex-column">
      <div className="page-layout">
        <div className="three-col-layout-parent-container">
          {showHeader && (
            <div className="three-col-layout-header">
              <div>{headerContent}</div>
            </div>
          )}
          <Row className="full-height-row">
            {showLeftSidebar && (
              <Col
                xxl={leftColSize}
                xl={leftColSize}
                lg={leftColSize}
                className="three-col-layout-border-right"
              >
                {leftSidebarContent}
              </Col>
            )}
            {showMiddleContainer && (
              <Col xxl={middleColSize} xl={middleColSize} lg={middleColSize}>
                {middleContainerContent}
              </Col>
            )}
            {showRightSidebar && (
              <Col
                xxl={rightColSize}
                xl={rightColSize}
                lg={rightColSize}
                className="three-col-layout-border-left"
              >
                {rightSidebarContent}
              </Col>
            )}
          </Row>
        </div>
      </div>
      <div className="footer-container">
        <div className="tenant-dashboard-bottom-divider" />
        {showFooter && (
          <div className="footer-padding">
            <div className="div-space-between">
              <VscLayoutSidebarLeftOff
                className="footer-button"
                onClick={onToggleLeftLayout}
              />
              <VscLayoutSidebarRightOff
                className="footer-button"
                onClick={onToggleRightLayout}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ThreeColLayout;
