import {
  createStageApi,
  createOrganisationApi,
  getOrganisationListApi,
  getOrganisationListForTenantApi,
  getStagesListApi,
} from 'src/api/organisation';

import {
  ORGANISATION_LIST,
  CREATE_STAGE,
  CREATE_ORGANISATION,
  STAGES_LIST,
} from 'src/redux/Actions/Type';

export const getStages = (organisationId) => (dispatch) =>
  getStagesListApi(organisationId).then((data) => {
    dispatch({
      type: STAGES_LIST,
      payload: data,
    });
  });

export const getOrganisationList = () => (dispatch) =>
  getOrganisationListApi().then((data) => {
    dispatch({
      type: ORGANISATION_LIST,
      payload: data,
    });
  });

export const getOrganisationListByTenant = (tenantId) => (dispatch) =>
  getOrganisationListForTenantApi(tenantId).then((data) => {
    dispatch({
      type: ORGANISATION_LIST,
      payload: data,
    });
  });

export const createOrganisation = (userData) => (dispatch) =>
  createOrganisationApi(userData).then((data) => {
    dispatch({
      type: CREATE_ORGANISATION,
      payload: data,
    });
  });

export const createStage = (userData) => (dispatch) =>
  createStageApi(userData).then((data) => {
    dispatch({
      type: CREATE_STAGE,
      payload: data,
    });
  });
