import React, { useState, useEffect } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPurchaseOrder,
  getBusinessPartners,
  getVehicleTrackingWorkflows,
} from 'src/redux/Actions/VehicleTracking';
import { VscSearch } from 'react-icons/vsc';
import 'src/style/entry.css';

export default function CreateVehicleTrackingMainComponent({
  setSelectedPurchaseOrder,
  selectedPurchaseOrder,
}) {
  const dispatch = useDispatch();
  const vehicleTrackingDataReducer = useSelector(
    (state) => state.VehicleTrackingReducer,
  );
  const authReducer = useSelector((state) => state.AuthReducer);
  const organisationId = authReducer.userData.organisations?.id;
  const [purchaseOrderSearchedTerm, setPurchaseOrderSearchedTerm] =
    useState(null);
  const [selectedBusinessPartner, setSelectedBusinessPartner] = useState(null);

  const handleSelectChange = (event) => {
    setSelectedBusinessPartner(event.target.value);
  };

  const filteredPurchaseOrderList =
    vehicleTrackingDataReducer?.purchaseOrderHeader || [];
  const allBusinessPartners =
    vehicleTrackingDataReducer?.businessPartners || [];

  const handlePurchaseOrderSelection = (e, poNumber) => {
    const updatedSelectedPoNumbers = [...selectedPurchaseOrder];
    if (e.target.checked) {
      updatedSelectedPoNumbers.push(poNumber);
    } else {
      const index = updatedSelectedPoNumbers.indexOf(poNumber);
      if (index > -1) {
        updatedSelectedPoNumbers.splice(index, 1);
      }
    }
    setSelectedPurchaseOrder(updatedSelectedPoNumbers);
  };

  const getPoNumberById = (id) => {
    const po = filteredPurchaseOrderList.find((entry) => entry.id === id);
    return po ? po.po_number : null;
  };

  const availableWorkflows = vehicleTrackingDataReducer.workflows;

  useEffect(() => {
    dispatch(
      getPurchaseOrder({
        poID: purchaseOrderSearchedTerm,
        supplier: selectedBusinessPartner,
        tenant_organisation_id: organisationId,
      }),
    );
    dispatch(getBusinessPartners(organisationId));
    dispatch(getVehicleTrackingWorkflows(organisationId));
  }, [
    dispatch,
    selectedBusinessPartner,
    purchaseOrderSearchedTerm,
    organisationId,
  ]);

  return (
    <div className="vehicle-entry-component">
      <span className="text-lg text-grey text-bold">Vehicle Entry</span>
      <div className="div-flex-column rg-5">
        <Row>
          <Col xxl={4} xl={4} lg={4}>
            <div className="div-flex-column">
              <div className="div-flex-row cg-5">
                <span className="text-md text-bold">Enter Vehicle Number:</span>
                <span className="text-red">*</span>
              </div>
              <input
                required
                name="vehicleNumber"
                className="modal-form-input"
                size="small"
                type="text"
              />
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4}>
            <div className="div-flex-column">
              <div className="div-flex-row cg-5">
                <span className="text-md text-bold">
                  Select Business Partner:
                </span>
                <span className="text-red">*</span>
              </div>
              <select
                required
                name="business-partner"
                className="modal-form-input"
                value={
                  selectedBusinessPartner !== null
                    ? selectedBusinessPartner
                    : ''
                }
                onChange={handleSelectChange}
              >
                <option value="">Select a Partner</option>
                {allBusinessPartners?.map((businessPartner) => (
                  <option key={businessPartner.id} value={businessPartner.name}>
                    {businessPartner.name}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4}>
            <div className="div-flex-column">
              <div className="div-flex-row cg-5">
                <span className="text-md text-bold">Select Workflow:</span>
                <span className="text-red">*</span>
              </div>
              <select required name="workflow" className="modal-form-input">
                <option value="">Select Workflow</option>
                {availableWorkflows?.map((workflow) => (
                  <option key={workflow.id} value={workflow.id}>
                    {workflow.name}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        </Row>
        <hr className="mt-1 mb-1" />
        <div className="div-flex-column rg-10">
          <Row>
            <Col xxl={6} xl={6} lg={6} xs={12}>
              <div className="div-flex-row cg-10">
                <div className="search-container">
                  <input
                    className="po-search-bar"
                    type="text"
                    placeholder="Search by PO"
                    name="search"
                    onChange={(e) =>
                      setPurchaseOrderSearchedTerm(e.target.value)
                    }
                  />
                  <VscSearch className="search-icon" color="grey" />
                </div>
              </div>
            </Col>
          </Row>
          {selectedPurchaseOrder?.length > 0 && (
            <div className="selected-pills-container">
              <div className="div-flex-column rg-10">
                <div className="div-flex-row div-space-between">
                  <div className="d-flex flex-wrap">
                    {selectedPurchaseOrder?.map((poID) => (
                      <span className="selected-po-pills" key={poID}>
                        {getPoNumberById(poID)}
                      </span>
                    ))}
                  </div>
                  <button
                    className="ms-3 pills-cancle-btn-style"
                    onClick={() => setSelectedPurchaseOrder([])}
                  >
                    Clear All
                  </button>
                </div>
                <div className="po-table">
                  <Table hover size="lg" className="table-cell-record">
                    <thead>
                      <tr>
                        <th className="vehicle-entry-table-head">
                          <span className="text-md text-grey">PO.No</span>
                        </th>
                        <th className="vehicle-entry-table-head">
                          <span className="text-md text-grey">EWAY Number</span>
                        </th>
                        <th className="vehicle-entry-table-head">
                          <span className="text-md text-grey">
                            Invoice Number
                          </span>
                          <span className="text-red">&nbsp;*</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedPurchaseOrder?.length > 0 ? (
                        selectedPurchaseOrder?.map((poID) => (
                          <tr key={poID}>
                            <td className="table-cell-min-width-100">
                              <span>{getPoNumberById(poID)}</span>
                            </td>
                            <td>
                              <div className="text-md">
                                <input
                                  id={`eway_bill_${poID}`}
                                  type="text"
                                  className="modal-form-input"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="text-md">
                                <input
                                  required
                                  id={`invoice_${poID}`}
                                  type="text"
                                  className="modal-form-input"
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colSpan="5" className="table-cell-min-width-100">
                            No Purchase order selected
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          )}
          <div className="po-table">
            <Table hover size="lg" className="table-cell-record">
              <thead>
                <tr>
                  <th className="vehicle-entry-table-head">
                    <span className="text-md text-grey">PO.No</span>
                  </th>
                  <th className="vehicle-entry-table-head">
                    <span className="text-md text-grey">Supplier</span>
                  </th>
                  <th className="vehicle-entry-table-head">
                    <span className="text-md text-grey">Arrival Date</span>
                  </th>
                  <th className="vehicle-entry-table-head">
                    <span className="text-md text-grey">Status</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredPurchaseOrderList?.length > 0 ? (
                  filteredPurchaseOrderList?.map((purchaseOrderDataObj) => (
                    <tr key={purchaseOrderDataObj.id}>
                      <td className="table-cell-min-width-100 border-left-blue">
                        <div className="div-flex-row cg-5">
                          <input
                            type="checkbox"
                            checked={selectedPurchaseOrder.includes(
                              purchaseOrderDataObj.id,
                            )}
                            onChange={(e) =>
                              handlePurchaseOrderSelection(
                                e,
                                purchaseOrderDataObj.id,
                              )
                            }
                            disabled={purchaseOrderDataObj.status === '4'}
                          />
                          <span>{purchaseOrderDataObj.po_number}</span>
                        </div>
                      </td>
                      <td className="table-cell-min-width-100">
                        <span>{purchaseOrderDataObj.supplier}</span>
                      </td>
                      <td className="table-cell-min-width-100">
                        <span>{purchaseOrderDataObj.arrival_date}</span>
                      </td>
                      <td className="table-cell-min-width-100">
                        <span
                          className={
                            purchaseOrderDataObj.status === '4'
                              ? 'red-pill bg-red'
                              : 'green-pill bg-green'
                          }
                        >
                          {purchaseOrderDataObj.status}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan="5" className="table-cell-min-width-100">
                      Select a business partner
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
