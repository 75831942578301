import { LoginApi, LogoutApi } from 'src/api/login';
import { LOGIN, LOGOUT } from 'src/redux/Actions/Type';

export const login = (isUserdata) => (dispatch) =>
  LoginApi(isUserdata).then((data) => {
    const {
      token,
      user_details: userdata,
      super_admin: superAdmin,
      organisation_admin: organisationAdmin,
      normal_user: normalUser,
      first_stage_assigned: firstStageAssigned,
    } = data;
    dispatch({
      type: LOGIN,
      payload: {
        userdata,
        token,
        superAdmin,
        organisationAdmin,
        normalUser,
        firstStageAssigned,
      },
    });
    return Promise.resolve(data);
  });

export const logout = () => (dispatch) => {
  LogoutApi().then(() => {
    dispatch({
      type: LOGOUT,
    });
  });
};
