import {
  GET_PURCHASE_ORDERS,
  CREATE_VEHICLE_TRACKING,
  GET_TARGET_VS_ACTUAL_FOR_CONFIG,
  GET_TIMELINE,
  GET_PO_LIST,
  GET_PO_ITEMS,
  MOVE_TO_NEXT_STAGE,
  GET_STAGE_DATA,
  GET_VEHICLE_TRAKING_REPORT_DATA,
  GET_VEHICLE_TRACKING_DETAILS,
  GET_BARCODE,
  GET_BUSINESS_PARTNER,
  GET_ASSIGNED_STAGES,
  GET_WORKFLOWS,
  GET_WORKFLOW_STAGES_FOR_VEHICLE_TRACKING,
  CLEAR_WORKFLOW_STAGES_FOR_VEHICLE_TRACKING,
  GET_TARGET_VS_ACTUAL_CONFIG_FOR_WORKFLOW_STAGE,
  CREATE_TARGET_VS_ACTUAL_LOGS,
  GET_VEHICLE_TRACKING_LIST,
  GET_NEXT_AVAILABLE_WORKFLOW_STAGES,
  SKIP_AND_MOVE_VT_TO_ANOTHER_WS,
  GET_VARIANCE_REPORT_DATA,
  GET_AVAILABLE_MATERIAL_CODES,
} from 'src/redux/Actions/Type';
const initialState = {
  targetVsActualItems: [],
  timeline: [],
  poDetailData: {},
  gateEntryDetailFilterPOItemByPONumber: [],
  skipTimeline: [],
  moveToExit: {},
  stationdata: [],
  stagesDetails: [],
  barcode: [],
  businessPartners: [],
  poItems: [],
  moveToNextStage: {},
  vehicleTrackingDetails: [],
  assignedStages: [],
  workflows: [],
  workflowStagesForVehicleTracking: [],
  targetVsActualConfig: [],
  targetVsActualLogs: {},
  vehicleTrackingList: [],
  vehicleTrackingWorkflowStagesData: {},
  skipAndMoveVtToAnotherWs: {},
  varianceReportData: [],
  availableMaterialCodesForOranisation: [],
};
const VehicleTrackingReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TIMELINE:
      return {
        ...state,
        timeline: payload,
      };
    case GET_VEHICLE_TRACKING_DETAILS:
      return {
        ...state,
        vehicleTrackingDetails: payload,
      };
    case GET_PO_ITEMS:
      return {
        ...state,
        poItems: payload,
      };
    case GET_TARGET_VS_ACTUAL_FOR_CONFIG:
      return {
        ...state,
        targetVsActualItems: payload,
      };
    case GET_PO_LIST:
      return {
        ...state,
        poDetailData: payload,
      };
    case MOVE_TO_NEXT_STAGE:
      return {
        ...state,
        moveToNextStage: payload,
      };
    case GET_STAGE_DATA:
      return {
        ...state,
        stationData: payload,
      };
    case GET_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrderHeader: payload,
      };
    case CREATE_VEHICLE_TRACKING:
      return {
        ...state,
        vehicleEntry: payload,
      };
    case GET_BARCODE:
      return {
        ...state,
        barcode: payload,
      };
    case GET_BUSINESS_PARTNER:
      return {
        ...state,
        businessPartners: payload,
      };
    case GET_VEHICLE_TRAKING_REPORT_DATA:
      return {
        ...state,
        gateReport: payload,
      };
    case GET_ASSIGNED_STAGES:
      return {
        ...state,
        assignedStages: payload,
      };
    case GET_WORKFLOWS:
      return {
        ...state,
        workflows: payload,
      };
    case GET_WORKFLOW_STAGES_FOR_VEHICLE_TRACKING:
      return {
        ...state,
        workflowStagesForVehicleTracking: payload,
      };
    case CLEAR_WORKFLOW_STAGES_FOR_VEHICLE_TRACKING:
      return {
        ...state,
        workflowStagesForVehicleTracking: payload,
      };
    case GET_TARGET_VS_ACTUAL_CONFIG_FOR_WORKFLOW_STAGE:
      return {
        ...state,
        targetVsActualConfig: payload,
      };
    case CREATE_TARGET_VS_ACTUAL_LOGS:
      return {
        ...state,
        targetVsActualLogs: payload,
      };
    case GET_VEHICLE_TRACKING_LIST:
      return {
        ...state,
        vehicleTrackingList: payload,
      };
    case GET_NEXT_AVAILABLE_WORKFLOW_STAGES:
      return {
        ...state,
        vehicleTrackingWorkflowStagesData: payload,
      };
    case SKIP_AND_MOVE_VT_TO_ANOTHER_WS:
      return {
        ...state,
        skipAndMoveVtToAnotherWs: payload,
      };
    case GET_VARIANCE_REPORT_DATA:
      return {
        ...state,
        varianceReportData: payload,
      };
    case GET_AVAILABLE_MATERIAL_CODES:
      return {
        ...state,
        availableMaterialCodesForOranisation: payload,
      };
    default:
      return state;
  }
};
export default VehicleTrackingReducer;
