import React from 'react';
import 'src/App.css';

function StatusCode403() {
  return (
    <div className="App">
      <header className="App-header">
        <p> 403 Forbidden </p>
      </header>
    </div>
  );
}

export default StatusCode403;
