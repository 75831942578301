import config from 'src/config/config';
import api from 'src/api/interceptor';

export function getStagesListApi(data) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-vehicle-tracking-stages/?tenant_organisation_id=${data}`,
    )
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
}

export function createOrganisationApi(data) {
  return api
    .post(
      `${config.test_url}api/organisation/create-organisation-for-tenant/`,
      data,
    )
    .then((res) => Promise.resolve(res));
}

export function createStageApi(data) {
  return api
    .post(
      `${config.test_url}api/vehicle-tracking/create-vehicle-tracking-stage/ `,
      data,
    )
    .then((res) => Promise.resolve(res));
}

export function getOrganisationListApi() {
  return api
    .get(`${config.test_url}api/organisation/get-organisations/`)
    .then((res) => Promise.resolve(res));
}

export function getOrganisationListForTenantApi(data) {
  return api
    .get(
      `${config.test_url}api/organisation/get-organisations/?tenant_id=${data}`,
    )
    .then((res) => Promise.resolve(res));
}
