import React from 'react';
import 'src/style/tenant.css';
import { useDispatch, useSelector } from 'react-redux';
import { createStage } from 'src/redux/Actions/Organisation';
import { MdClose } from 'react-icons/md';

export default function CreateStageForm({ onClose }) {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.AuthReducer);
  const organisationId = authReducer.userData.organisations?.id;

  const handleCreateStageFormSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const stageData = {
      name: data.get('name'),
      stage_order: data.get('stage-order'),
      description: data.get('description'),
      tenant_organisation_id: organisationId,
    };
    dispatch(createStage(stageData)).then(() => {
      window.location.reload();
    });
  };

  return (
    <form
      onSubmit={handleCreateStageFormSubmit}
      className="div-flex-column right-form-layout div-space-between"
    >
      <div className="div-flex-column rg-20">
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xxxl text-bold">Stage Form</span>
          <MdClose size="18px" onClick={onClose} />
        </div>
        <div className="form-body-section div-flex-column rg-20">
          <div className="div-flex-column">
            <span className="text-md text-bold">Name:</span>
            <input
              name="name"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
          <div className="div-flex-column">
            <span className="text-md text-bold">Stage Order:</span>
            <input
              name="stage-order"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
          <div className="div-flex-column">
            <span className="text-md text-bold">Description:</span>
            <input
              name="description"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="div-flex-end">
        <button className="btn-black" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
}
