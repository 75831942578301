import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { PiTruckDuotone } from 'react-icons/pi';
import { LightTooltip } from 'src/components/Layout/ToolTip';
import { useNavigate } from 'react-router-dom';

export default function StageDashboardMainContent({
  showRightSidebar,
  toggleRightSidebarTable,
  setSelectedStageId,
  selectedStageId,
}) {
  const navigate = useNavigate();
  const vehicleTrackingDataReducer = useSelector(
    (state) => state.VehicleTrackingReducer,
  );
  const { stationData } = vehicleTrackingDataReducer;
  const colSize = showRightSidebar ? 6 : 3;
  const trimLongString = (inputString) => {
    const parts = inputString.split(' ');
    const result = `${parts[0].slice(0, 24)}`;
    return result;
  };
  const assignedStagesData = vehicleTrackingDataReducer.assignedStages;

  const moveToVehicleTrackingDetailPage = (
    workflowStageId,
    vehicleTrackingNumber,
  ) => {
    navigate(`/${workflowStageId}/${vehicleTrackingNumber}`);
  };

  const handleStageChange = (stageId) => {
    setSelectedStageId(stageId);
  };

  return (
    <div className="page-container">
      <div className="div-space-between div-align-center">
        <div className="div-flex-row cg-10">
          {assignedStagesData?.length > 1 ? (
            assignedStagesData.map((assignedStagesObj) => (
              <button
                key={assignedStagesObj.id}
                onClick={() => handleStageChange(assignedStagesObj.id)}
                className={
                  selectedStageId === assignedStagesObj.id
                    ? 'active-assigned-stage'
                    : 'assigned-stage-options'
                }
              >
                {assignedStagesObj.name}
              </button>
            ))
          ) : (
            <span className="text-xl text-bold">
              {assignedStagesData[0]?.name}
            </span>
          )}
        </div>
        {!showRightSidebar && (
          <button className="btn-black" onClick={toggleRightSidebarTable}>
            Scan
          </button>
        )}
      </div>
      <Container className="p-2 m-0">
        <Row className="current-stage-container">
          {stationData?.incomingVehicleTracking && (
            <Col xxl={4} xl={4} lg={4} className="p-1">
              <div className="div-flex-row div-align-center div-space-between stage-entry-card bg-blue">
                <div className="div-flex-column rg-5">
                  <span className="text-xxxl text-bold">
                    {stationData?.incomingVehicleTracking?.count}
                  </span>
                  <span className="text-md text-400">Incoming</span>
                </div>
              </div>
            </Col>
          )}
          <Col xxl={4} xl={4} lg={4} className="p-1">
            <div className="div-flex-row div-align-center div-space-between stage-entry-card bg-orange">
              <div className="div-flex-column rg-5">
                <span className="text-xxxl text-bold">
                  {stationData?.currentVehicleTracking?.count}
                </span>
                <span className="text-md text-400">Current</span>
              </div>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} className="p-1">
            <div className="div-flex-row div-align-center div-space-between stage-entry-card bg-green">
              <div className="div-flex-column rg-5">
                <span className="text-xxxl text-bold">
                  {stationData?.completedVehicleTracking?.count}
                </span>
                <span className="text-md text-400">Completed</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {stationData?.incomingVehicleTracking && (
        <>
          <div className="div-flex-row div-space-between header-hr">
            <span className="text-xl text-bold">Incoming</span>
            <span className="text-md text-400">
              {stationData?.incomingVehicleTracking?.count} Vehicles
            </span>
          </div>
          <Container className="p-2 m-0">
            <Row>
              {stationData?.incomingVehicleTracking?.count > 0 ? (
                stationData?.incomingVehicleTracking?.data.map((data) => (
                  <Col
                    xxl={colSize}
                    xl={colSize}
                    lg={colSize}
                    key={data.vehicle_tracking_number}
                    className="p-1"
                  >
                    <div className="div-flex-column stage-entry-card">
                      <div className="stage-card-workflow-pill text-sm">
                        <span className="bg-red red-pill">{data.workflow}</span>
                      </div>
                      <div className="div-flex-column rg-5">
                        <div className="div-flex-row cg-10 div-align-center">
                          <div className="stage-icon-outline bg-blue">
                            <PiTruckDuotone size="24px" />
                          </div>
                          <div className="div-flex-column">
                            <span className="text-md text-400">
                              <LightTooltip
                                title={data.vehicle_tracking_number}
                              >
                                <span>
                                  {trimLongString(data.vehicle_tracking_number)}
                                  ....
                                </span>
                              </LightTooltip>
                            </span>
                            <span className="text-md text-bold">
                              {data.vehicle_number}
                            </span>
                          </div>
                        </div>
                        <span className="text-md text-grey text-bold">
                          Status
                        </span>
                        <div className="div-flex-row stage-card-detail">
                          <div>
                            <ul className="timeline-container-stage">
                              <li className="timeline-progress-stage is-complete timeline-list">
                                <p className="timeline-text">
                                  <span className="text-lg text-bold">
                                    {data.stage1?.workflow_stage_name}
                                  </span>
                                  <br />
                                  <span className="text-md">
                                    {data.stage1.timestamp}{' '}
                                    {data.stage1.timestamp_string}.
                                  </span>
                                  <br />
                                </p>
                              </li>
                              <li className="timeline-progress-stage is-pending timeline-list">
                                <p className="timeline-text">
                                  <span className="text-lg text-bold">
                                    {data.stage2.workflow_stage_name}
                                  </span>
                                  <br />
                                  <span className="text-md">
                                    Estimated {data.stage2.timestamp}.
                                  </span>
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <button
                          className="btn-black"
                          onClick={() =>
                            moveToVehicleTrackingDetailPage(
                              data.stage2.workflow_stage_id,
                              data.vehicle_tracking_number,
                            )
                          }
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <Col>
                  <span className="text-md text-grey text-bold">
                    No vehicle available
                  </span>
                </Col>
              )}
            </Row>
          </Container>
        </>
      )}
      <div className="div-flex-row div-space-between header-hr">
        <span className="text-xl text-bold">Current</span>
        <span className="text-md text-400">
          {stationData?.currentVehicleTracking?.count} Vehicles
        </span>
      </div>
      <Container className="p-2 m-0">
        <Row>
          {stationData?.currentVehicleTracking?.count > 0 ? (
            stationData?.currentVehicleTracking?.data.map((data) => (
              <Col
                xxl={colSize}
                xl={colSize}
                lg={colSize}
                key={data.vehicle_tracking_number}
                className="p-1"
              >
                <div className="div-flex-column stage-entry-card">
                  <div className="stage-card-workflow-pill text-sm">
                    <span className="bg-red red-pill">{data.workflow}</span>
                  </div>
                  <div className="div-flex-column rg-5">
                    <div className="div-flex-row cg-10 div-align-center">
                      <div className="stage-icon-outline bg-orange">
                        <PiTruckDuotone size="24px" />
                      </div>
                      <div className="div-flex-column">
                        <span className="text-md text-400">
                          <LightTooltip title={data.vehicle_tracking_number}>
                            <span>
                              {trimLongString(data.vehicle_tracking_number)}
                              ....
                            </span>
                          </LightTooltip>
                        </span>
                        <span className="text-md text-bold">
                          {data.vehicle_number}
                        </span>
                      </div>
                    </div>
                    <span className="text-md text-grey text-bold">Status</span>
                    <div className="div-flex-column rg-5">
                      <div className="div-flex-row stage-card-detail">
                        <div>
                          <ul className="timeline-container-stage">
                            {data?.stage1 && (
                              <li className="timeline-progress-stage is-complete timeline-list">
                                <p className="timeline-text">
                                  <span className="text-lg text-bold">
                                    {data.stage1?.workflow_stage_name}
                                  </span>
                                  <br />
                                  <span className="text-md">
                                    {data.stage1?.timestamp}{' '}
                                    {data.stage1?.timestamp_string}.
                                  </span>
                                  <br />
                                </p>
                              </li>
                            )}
                            <li className="timeline-progress-stage is-complete timeline-list">
                              <p className="timeline-text">
                                <span className="text-lg text-bold">
                                  {data.stage2.workflow_stage_name}
                                </span>
                                <br />
                                <span className="text-md">
                                  {data.stage2.timestamp}{' '}
                                  {data.stage2.timestamp_string}.
                                </span>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <button
                        className="btn-black"
                        onClick={() =>
                          moveToVehicleTrackingDetailPage(
                            data.stage2.workflow_stage_id,
                            data.vehicle_tracking_number,
                          )
                        }
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <Col>
              <span className="text-md text-grey text-bold">
                No vehicle available
              </span>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}
