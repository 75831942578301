import React, { useState } from 'react';
import 'src/style/tenant.css';
import TenantList from 'src/components/Tenant/TenantList';
import TenantDetail from 'src/components/Tenant/TenantDetail';
import CreateTenantForm from 'src/components/Tenant/CreateTenantForm';
import ThreeColLayout from 'src/components/Layout/ThreeColLayout';

export default function TenantManagementLayout() {
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [showCreateTenantForm, setShowCreateTenantForm] = useState(false);

  const handleTenantSelection = (tenant) => {
    setSelectedTenant(tenant);
  };

  const handleTenantDeselection = () => {
    setSelectedTenant(null);
  };

  const toggleRightLayout = () => {
    setShowCreateTenantForm(!showCreateTenantForm);
  };

  const calculateColumnSizes = () => {
    if (selectedTenant && showCreateTenantForm) {
      return { leftColSize: 3, middleColSize: 6, rightColSize: 3 };
    }
    if (selectedTenant) {
      return { leftColSize: 3, middleColSize: 9, rightColSize: 0 };
    }
    if (showCreateTenantForm) {
      return { leftColSize: 0, middleColSize: 8, rightColSize: 4 };
    }
    return { leftColSize: 0, middleColSize: 12, rightColSize: 0 };
  };

  const { leftColSize, middleColSize, rightColSize } = calculateColumnSizes();

  return (
    <ThreeColLayout
      showLeftSidebar={selectedTenant}
      showMiddleContainer
      showRightSidebar={showCreateTenantForm}
      leftSidebarContent={
        <TenantDetail
          tenant={selectedTenant}
          onClose={handleTenantDeselection}
        />
      }
      middleContainerContent={
        <TenantList
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
          onSelectTenant={handleTenantSelection}
          onDeselectTenant={handleTenantDeselection}
          onToggleShowCreateTenantForm={toggleRightLayout}
          tenantForm={showCreateTenantForm}
          toggleRightLayout={toggleRightLayout}
        />
      }
      rightSidebarContent={
        showCreateTenantForm ? (
          <CreateTenantForm onClose={toggleRightLayout} />
        ) : null
      }
      showFooter
      onToggleLeftLayout={handleTenantDeselection}
      onToggleRightLayout={toggleRightLayout}
      leftColSize={leftColSize}
      middleColSize={middleColSize}
      rightColSize={rightColSize}
    />
  );
}
