import React, { useEffect } from 'react';
import 'src/style/tenant.css';
import { useDispatch, useSelector } from 'react-redux';
import { getTenantList } from 'src/redux/Actions/Tenant';
import { createOrganisation } from 'src/redux/Actions/Organisation';
import { MdClose } from 'react-icons/md';

export default function CreateOrganisationForm({ onClose }) {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.AuthReducer);
  const isSuperAdmin = authReducer.superAdmin;
  const tenantId = authReducer.userData.tenant?.id;
  useEffect(() => {
    if (isSuperAdmin === true) {
      dispatch(getTenantList());
    }
  }, [dispatch]);
  const tenantReducer = useSelector((state) => state.TenantReducer);
  const tenantData = tenantReducer?.tenantList;

  const handleCreateOrganisationFormSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const defaultTenantId = isSuperAdmin ? data.get('tenant') : tenantId;
    const organisationData = {
      name: data.get('name'),
      gst_number: data.get('gst-number'),
      tenant_id: defaultTenantId,
      description: data.get('description'),
    };
    dispatch(createOrganisation(organisationData)).then(() => {
      window.location.reload();
    });
  };

  return (
    <form
      onSubmit={handleCreateOrganisationFormSubmit}
      className="div-flex-column right-form-layout div-space-between"
    >
      <div className="div-flex-column rg-20">
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xxxl text-bold">Organisation Form</span>
          <MdClose size="18px" onClick={onClose} />
        </div>
        <div className="form-body-section div-flex-column rg-20">
          {isSuperAdmin && (
            <div className="div-flex-column">
              <span className="text-md text-bold">Tenant:</span>
              <select name="tenant" className="modal-form-input">
                <option value="">Select a tenant</option>
                {tenantData.map((tenant) => (
                  <option key={tenant.tenant_id} value={tenant.tenant_id}>
                    {tenant.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="div-flex-column">
            <span className="text-md text-bold">Name:</span>
            <input
              name="name"
              className="modal-form-input"
              size="small"
              type="text"
              required
            />
          </div>
          <div className="div-flex-column">
            <span className="text-md text-bold">GST Number:</span>
            <input
              name="gst-number"
              className="modal-form-input"
              size="small"
              type="text"
              required
            />
          </div>
          <div className="div-flex-column">
            <span className="text-md text-bold">Description:</span>
            <input
              name="description"
              className="modal-form-input"
              size="small"
              type="text"
              required
            />
          </div>
        </div>
      </div>
      <div className="div-flex-end">
        <button className="btn-black" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
}
