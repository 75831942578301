import React, { useState } from 'react';
import TargetVsActualForm from 'src/components/TargetVsActual/TargetVsActualForm';
import TargetVsActualTimeline from 'src/components/TargetVsActual/TargetVsActualTimeline';
import ThreeColLayout from 'src/components/Layout/ThreeColLayout';
import TargetVsActualMainContent from 'src/components/TargetVsActual/TargetVsActualMainContent';

function TargetVsActualLayout() {
  const [showTimeline, setShowTimeline] = useState(true);
  const [showTargetVsActualForm, setShowTargetVsActualForm] = useState(false);
  const [selectedTargetVsActualConfig, setSelectedTargetVsActualConfig] =
    useState(null);

  const toggleLeftLayout = () => {
    setShowTimeline(!showTimeline);
    setShowTargetVsActualForm(false);
  };

  const toggleRightLayout = (config) => {
    setSelectedTargetVsActualConfig(config);
    setShowTargetVsActualForm(!showTargetVsActualForm);
    setShowTimeline(false);
  };

  const calculateColumnSizes = () => {
    if (!showTimeline && !showTargetVsActualForm) {
      return { leftColSize: 0, middleColSize: 12, rightColSize: 0 };
    }
    if (showTargetVsActualForm) {
      return { leftColSize: 0, middleColSize: 7, rightColSize: 5 };
    }
    return { leftColSize: 3, middleColSize: 9, rightColSize: 0 };
  };

  const { leftColSize, middleColSize, rightColSize } = calculateColumnSizes();

  return (
    <ThreeColLayout
      showLeftSidebar={showTimeline}
      showMiddleContainer
      showRightSidebar={showTargetVsActualForm}
      leftSidebarContent={<TargetVsActualTimeline />}
      middleContainerContent={
        <TargetVsActualMainContent
          toggleForm={toggleRightLayout}
          isFormOpen={showTargetVsActualForm}
        />
      }
      rightSidebarContent={
        <TargetVsActualForm
          selectedTargetVsActualConfig={selectedTargetVsActualConfig}
          toggleForm={toggleRightLayout}
        />
      }
      showFooter
      onToggleLeftLayout={toggleLeftLayout}
      onToggleRightLayout={toggleRightLayout}
      leftColSize={leftColSize}
      middleColSize={middleColSize}
      rightColSize={rightColSize}
    />
  );
}

export default TargetVsActualLayout;
