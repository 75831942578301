import React, { useEffect } from 'react';
import 'src/style/vehicleTrackingReport.css';
import { Col, Row, Table } from 'react-bootstrap';
import WaitingTimeChart from 'src/components/Report/WaitingTimeChart';
import TargetVsActualChart from 'src/components/Report/TargetVsActualChart';
import { getVehicleTrackingReportData } from 'src/redux/Actions/VehicleTracking';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PiTruckDuotone } from 'react-icons/pi';
import { GoDotFill } from 'react-icons/go';

export default function VehicleTrackingReport() {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const authReducer = useSelector((state) => state.AuthReducer);
  const organisationId = authReducer.userData.organisations?.id;

  useEffect(() => {
    dispatch(getVehicleTrackingReportData(slug, organisationId));
  }, [dispatch, slug, organisationId]);

  const vehicleTrakingDataReducer = useSelector(
    (state) => state?.VehicleTrackingReducer,
  );

  const reportData = vehicleTrakingDataReducer.gateReport;

  return (
    <div className="page-layout">
      <div className="page-container div-flex-column rg-5">
        <div className="div-space-between report-header">
          <span className="text-bold text-xl">Vehicle Report</span>
          <div className="div-flex-row cg-10">
            <div className="div-flex-row cg-5 div-align-center bg-green green-pill">
              <PiTruckDuotone size="18px" />
              <span className="text-lg">{reportData?.vehicleNumber}</span>
            </div>
            <div className="div-flex-row cg-5 div-align-center bg-red red-pill">
              <GoDotFill size="18px" className="pill-animation" />
              <span className="text-lg">{slug}</span>
            </div>
          </div>
        </div>
        <div className="report-content div-flex-column rg-10">
          <Row className="g-2">
            <Col xxl={6} xl={6} lg={6}>
              <div className="report-detail-card">
                <span className="text-xl text-bold">Waiting Time</span>
                <WaitingTimeChart
                  time={reportData?.stageDurationsChart?.stage_duration_list}
                  stages={reportData?.stageDurationsChart?.stage_name_list}
                />
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6}>
              <div className="report-detail-card">
                <span className="text-xl text-bold">Target VS Actual</span>
                <TargetVsActualChart
                  overallSuccess={reportData?.successRateList}
                  stages={reportData?.stageDurationsChart?.stage_name_list}
                />
              </div>
            </Col>
          </Row>
          <div className="report-detail-card div-flex-column rg-10">
            <span className="text-xl text-bold">Station Data</span>
            <div className="report-table">
              <Table hover size="lg" className="table-cell-record">
                <thead>
                  <tr>
                    <th className="bg-grey table-cell-min-width-100">
                      <div className="text-lg text-bold">Purchase Order</div>
                    </th>
                    <th className="bg-grey table-cell-min-width-100">
                      <div className="text-lg text-bold">Title</div>
                    </th>
                    <th className="bg-grey table-cell-min-width-100">
                      <div className="text-lg text-bold">Table</div>
                    </th>
                    <th className="bg-grey table-cell-min-width-100">
                      <div className="text-lg text-bold">Field</div>
                    </th>
                    <th className="bg-grey table-cell-min-width-100">
                      <div className="text-lg text-bold">Target</div>
                    </th>
                    <th className="bg-grey table-cell-min-width-100">
                      <div className="text-lg text-bold">Actual</div>
                    </th>
                    <th className="bg-grey table-cell-min-width-100">
                      <div className="text-lg text-bold">Success Rate</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportData?.stagesData?.map((stageData) => (
                    <React.Fragment key={stageData.id}>
                      <tr>
                        <td colSpan="7" className="table-cell-stage-name">
                          <div className="div-flex-row div-space-between div-align-center">
                            <div className="div-flex-row cg-10 div-align-center">
                              <span className="text-md text-bold">
                                {stageData.name}
                              </span>
                              {stageData.skipped === true && (
                                <div className="red-pill bg-red">
                                  <span className="text-md">Skipped</span>
                                </div>
                              )}
                            </div>
                            {!stageData.skipped && stageData.supplier && (
                              <div className="text-md text-bold">
                                Supplier Name- {stageData.supplier}
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      {!stageData.skipped &&
                        stageData.target_vs_actual_list?.map(
                          (targetVsActualList) => (
                            <React.Fragment key={targetVsActualList.config_id}>
                              <tr>
                                <td colSpan="7" className="text-md">
                                  Process: {targetVsActualList.title}
                                </td>
                              </tr>
                              {targetVsActualList.target_vs_actual.map(
                                (targetVsActualData) => (
                                  <tr key={targetVsActualData.id}>
                                    <td className="text-md table-cell-min-width-100">
                                      {targetVsActualData.purchase_order}
                                    </td>
                                    <td className="text-md table-cell-min-width-100">
                                      {targetVsActualData.name}
                                    </td>
                                    <td className="text-md table-cell-min-width-100">
                                      {targetVsActualData.table}
                                    </td>
                                    <td className="text-md table-cell-min-width-100">
                                      {targetVsActualData.field}
                                    </td>
                                    <td className="text-md table-cell-min-width-100">
                                      {targetVsActualData.target}
                                    </td>
                                    <td className="text-md table-cell-min-width-100">
                                      {targetVsActualData.actual}
                                    </td>
                                    <td className="text-md table-cell-min-width-100">
                                      {targetVsActualData.success_rate}
                                    </td>
                                  </tr>
                                ),
                              )}
                            </React.Fragment>
                          ),
                        )}
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
