import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import 'src/style/tenant.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrganisationList,
  getOrganisationListByTenant,
} from 'src/redux/Actions/Organisation';

export default function OrganisationList({
  selectedOrganisation,
  setSelectedOrganisation,
  onSelectOrganisation,
  ontoggleRightLayout,
  organisationForm,
  onDeselectOrganisation,
}) {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.AuthReducer);
  const tenantId = authReducer.userData.tenant?.id;
  const isSuperAdmin = authReducer.superAdmin === true;
  useEffect(() => {
    if (isSuperAdmin === true) {
      dispatch(getOrganisationList());
    } else {
      dispatch(getOrganisationListByTenant(tenantId));
    }
  }, [dispatch]);

  const organisationReducer = useSelector((state) => state.OrganisationReducer);
  const organisationData = organisationReducer?.organisationList;

  const handleCheckboxChange = (organisationDataObj, isChecked) => {
    if (isChecked) {
      setSelectedOrganisation(organisationDataObj);
      onSelectOrganisation(organisationDataObj);
    } else {
      setSelectedOrganisation(null);
      onDeselectOrganisation();
    }
  };

  return (
    <div className="div-flex-column rg-20">
      <div className="div-flex-column">
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xxxl text-bold">Organisation Management</span>
          {!organisationForm && (
            <button className="btn-black" onClick={ontoggleRightLayout}>
              Add Organisation
            </button>
          )}
        </div>
        <span className="text-md text-400">
          {organisationData?.length} Organisation
        </span>
      </div>
      <div className="po-table">
        <Table hover size="lg" className="table-cell-record">
          <thead>
            <tr>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100 table-id-column-padding">
                  ID
                </div>
              </th>
              {isSuperAdmin && (
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-100">
                    Tenant Name
                  </div>
                </th>
              )}
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  Organisation Name
                </div>
              </th>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  GST Number
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="div-scrollable-details">
            {organisationData?.length > 0 ? (
              organisationData.map((organisationDataObj) => (
                <tr key={organisationDataObj.id}>
                  <td className="table-cell-min-width-100">
                    <div className="div-flex-row cg-10 div-align-center">
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={selectedOrganisation === organisationDataObj}
                        onChange={(e) =>
                          handleCheckboxChange(
                            organisationDataObj,
                            e.target.checked,
                          )
                        }
                      />
                      <div className="text-md text-400 text-center">
                        #{organisationDataObj.id}
                      </div>
                    </div>
                  </td>
                  {isSuperAdmin && (
                    <td className="table-cell-min-width-100">
                      <div className="text-md text-400">
                        {organisationDataObj.tenant_name}
                      </div>
                    </td>
                  )}
                  <td className="table-cell-min-width-100">
                    <div className="text-md text-400">
                      {organisationDataObj.name}
                    </div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md text-400">
                      {organisationDataObj.gst_number}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan="6">
                  <div className="text-md text-400">
                    No Organisation Created
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
