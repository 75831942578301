import React from 'react';
import 'src/style/tenant.css';
import { Link } from 'react-router-dom';
import { VscOrganization } from 'react-icons/vsc';
import { BsBuildings, BsModem } from 'react-icons/bs';
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function CommonDashboard() {
  const authReducer = useSelector((state) => state.AuthReducer);
  const isSuperAdmin = authReducer.superAdmin;
  return (
    <div className="page-layout">
      <div className="div-flex-column rg-20">
        <div className="div-flex-column">
          <span className="text-xxxl text-bold">Tenant Management</span>
          {isSuperAdmin ? (
            <span className="text-sm">Manage your Station and People.</span>
          ) : (
            <span className="text-sm">
              Manage your People, Tenant and Organisation.
            </span>
          )}
        </div>
        {isSuperAdmin ? (
          <div className="div-flex-column rg-10">
            <Row>
              <Col
                xxl={3}
                xl={3}
                lg={4}
                md={4}
                sm={4}
                xs={12}
                className="tenant-dashboard-card"
              >
                <Container>
                  <Link to="/tenant-management/" className="decoration-none">
                    <div className="div-flex-column rg-20">
                      <div className="div-flex-column">
                        <div className="div-flex-row cg-10 div-align-center">
                          <HiOutlineBuildingOffice2 size="18px" />
                          <span className="text-lg text-bold">Tenant</span>
                        </div>
                        <span className="text-md text-grey">
                          Create and Manage Tenants
                        </span>
                      </div>
                      <span className="text-md link-text-blue">Tenant</span>
                    </div>
                  </Link>
                </Container>
              </Col>
              <Col
                xxl={3}
                xl={3}
                lg={4}
                md={4}
                sm={4}
                xs={12}
                className="tenant-dashboard-card"
              >
                <Container>
                  <Link
                    to="/organisation-management/"
                    className="decoration-none"
                  >
                    <div className="div-flex-column rg-20">
                      <div className="div-flex-column">
                        <div className="div-flex-row cg-10 div-align-center">
                          <BsBuildings size="18px" />
                          <span className="text-lg text-bold">
                            Organisation
                          </span>
                        </div>
                        <span className="text-md text-grey">
                          Create and Manage Organisation
                        </span>
                      </div>
                      <span className="text-md link-text-blue">
                        Organisation
                      </span>
                    </div>
                  </Link>
                </Container>
              </Col>
              <Col
                xxl={3}
                xl={3}
                lg={4}
                md={4}
                sm={4}
                xs={12}
                className="tenant-dashboard-card"
              >
                <Container>
                  <Link to="/users/" className="decoration-none">
                    <div className="div-flex-column rg-20">
                      <div className="div-flex-column">
                        <div className="div-flex-row cg-10 div-align-center">
                          <VscOrganization size="18px" />
                          <span className="text-lg text-bold">Admin</span>
                        </div>
                        <span className="text-md text-grey">
                          Create and Manage Admin
                        </span>
                      </div>
                      <span className="text-md link-text-blue">People</span>
                    </div>
                  </Link>
                </Container>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="div-flex-column rg-10">
            <Row>
              <Col
                xxl={3}
                xl={3}
                lg={4}
                md={4}
                sm={4}
                xs={12}
                className="tenant-dashboard-card"
              >
                <Container>
                  <Link
                    to="/organisation-management/"
                    className="decoration-none"
                  >
                    <div className="div-flex-column rg-20">
                      <div className="div-flex-column">
                        <div className="div-flex-row cg-10 div-align-center">
                          <BsBuildings size="18px" />
                          <span className="text-lg text-bold">
                            Organisation
                          </span>
                        </div>
                        <span className="text-md text-grey">
                          Create and Manage Organisation
                        </span>
                      </div>
                      <span className="text-md link-text-blue">
                        Organisation
                      </span>
                    </div>
                  </Link>
                </Container>
              </Col>
              <Col
                xxl={3}
                xl={3}
                lg={4}
                md={4}
                sm={4}
                xs={12}
                className="tenant-dashboard-card"
              >
                <Container>
                  <Link to="/users/" className="decoration-none">
                    <div className="div-flex-column rg-20">
                      <div className="div-flex-column">
                        <div className="div-flex-row cg-10 div-align-center">
                          <VscOrganization size="18px" />
                          <span className="text-lg text-bold">Users</span>
                        </div>
                        <span className="text-md text-grey">
                          Create and Manage Users
                        </span>
                      </div>
                      <span className="text-md link-text-blue">People</span>
                    </div>
                  </Link>
                </Container>
              </Col>
              <Col
                xxl={3}
                xl={3}
                lg={4}
                md={4}
                sm={4}
                xs={12}
                className="tenant-dashboard-card"
              >
                <Container>
                  <Link to="/stage-management/" className="decoration-none">
                    <div className="div-flex-column rg-20">
                      <div className="div-flex-column">
                        <div className="div-flex-row cg-10 div-align-center">
                          <BsModem size="18px" />
                          <span className="text-lg text-bold">Stages</span>
                        </div>
                        <span className="text-md text-grey">
                          Create and Manage Stages
                        </span>
                      </div>
                      <span className="text-md link-text-blue">Stages</span>
                    </div>
                  </Link>
                </Container>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
}
