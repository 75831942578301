import {
  getTenantListApi,
  createTenantApi,
  getAllUsersApi,
  updateUserProfileApi,
  createUserApi,
  getUsersForTenantApi,
} from 'src/api/tenant';

import {
  TENANT_LIST,
  CREATE_TENANT,
  ALL_USERS,
  UPDATE_PROFILE,
  CREATE_USER,
} from 'src/redux/Actions/Type';

export const getTenantList = () => (dispatch) =>
  getTenantListApi().then((data) => {
    dispatch({
      type: TENANT_LIST,
      payload: data,
    });
  });

export const createTenant = (userData) => (dispatch) =>
  createTenantApi(userData).then((data) => {
    dispatch({
      type: CREATE_TENANT,
      payload: data,
    });
  });

export const getTenantUsers = (tenantId) => (dispatch) =>
  getUsersForTenantApi(tenantId).then((data) => {
    dispatch({
      type: ALL_USERS,
      payload: data,
    });
  });

export const getAllUsers = () => (dispatch) =>
  getAllUsersApi().then((data) => {
    dispatch({
      type: ALL_USERS,
      payload: data,
    });
  });

export const updateProfile = (userData) => (dispatch) =>
  updateUserProfileApi(userData).then((data) => {
    dispatch({
      type: UPDATE_PROFILE,
      payload: data,
    });
  });

export const createUser = (userData) => (dispatch) =>
  createUserApi(userData).then((data) => {
    dispatch({
      type: CREATE_USER,
      payload: data,
    });
  });
