import React, { useState } from 'react';
import { MenuItem, Avatar, Popover } from '@mui/material';
import {
  StyledPopover,
  StyledAccountIconButton,
} from 'src/components/Layout/NavbarStyles';
import ExpoundUser from 'src/Images/avatar.jpg';
import 'src/style/sidebar.css';
import { useDispatch } from 'react-redux';
import { logout } from 'src/redux/Actions/Auth';
import { useNavigate } from 'react-router-dom';

function AccountPopover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };
  const LogoutUser = () => {
    dispatch(logout());
    setOpen(null);
    navigate('/login');
  };
  return (
    <div className="avatar-padding">
      <StyledAccountIconButton
        onClick={handleOpen}
        className={
          open ? 'account-pop-over-icon' : 'account-pop-over-icon-button'
        }
      >
        <Avatar
          src={ExpoundUser}
          alt="photoURL"
          id="accountIcon"
          style={{ width: '35px', height: '35px' }}
        />
      </StyledAccountIconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { StyledPopover },
        }}
      >
        <MenuItem className="popover-menu-item">
          <span className="navbarPopOver">Welcome User</span>
        </MenuItem>
        <MenuItem className="popover-menu-item" onClick={LogoutUser}>
          <span className="navbarPopOver">Logout</span>
        </MenuItem>
      </Popover>
    </div>
  );
}

export default AccountPopover;
