import React, { useState } from 'react';
import 'src/style/tenant.css';
import OrganisationDetail from 'src/components/Organisation/OrganisationDetail';
import OrganisationList from 'src/components/Organisation/OrganisationList';
import ThreeColLayout from 'src/components/Layout/ThreeColLayout';
import CreateOrganisationForm from 'src/components/Organisation/CreateOrganisationForm';

export default function OrganisationManagementLayout() {
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [showCreateOrganisationForm, setShowCreateOrganisationForm] =
    useState(false);

  const handleOrganisationSelection = (Organisation) => {
    setSelectedOrganisation(Organisation);
  };

  const handleOrganisationDeselection = () => {
    setSelectedOrganisation(null);
  };

  const toggleRightLayout = () => {
    setShowCreateOrganisationForm(!showCreateOrganisationForm);
  };

  const calculateColumnSizes = () => {
    if (selectedOrganisation && showCreateOrganisationForm) {
      return { leftColSize: 3, middleColSize: 6, rightColSize: 3 };
    }
    if (selectedOrganisation) {
      return { leftColSize: 3, middleColSize: 9, rightColSize: 0 };
    }
    if (showCreateOrganisationForm) {
      return { leftColSize: 0, middleColSize: 8, rightColSize: 4 };
    }
    return { leftColSize: 0, middleColSize: 12, rightColSize: 0 };
  };

  const { leftColSize, middleColSize, rightColSize } = calculateColumnSizes();

  return (
    <ThreeColLayout
      showLeftSidebar={selectedOrganisation}
      showMiddleContainer
      showRightSidebar={showCreateOrganisationForm}
      leftSidebarContent={
        <OrganisationDetail
          Organisation={selectedOrganisation}
          onClose={handleOrganisationDeselection}
        />
      }
      middleContainerContent={
        <OrganisationList
          selectedOrganisation={selectedOrganisation}
          setSelectedOrganisation={setSelectedOrganisation}
          onSelectOrganisation={handleOrganisationSelection}
          onDeselectOrganisation={handleOrganisationDeselection}
          ontoggleRightLayout={toggleRightLayout}
          organisationForm={showCreateOrganisationForm}
          toggleRightLayout={toggleRightLayout}
        />
      }
      rightSidebarContent={
        showCreateOrganisationForm ? (
          <CreateOrganisationForm onClose={toggleRightLayout} />
        ) : null
      }
      showFooter
      onToggleLeftLayout={handleOrganisationDeselection}
      onToggleRightLayout={toggleRightLayout}
      leftColSize={leftColSize}
      middleColSize={middleColSize}
      rightColSize={rightColSize}
    />
  );
}
