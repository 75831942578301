import React from 'react';
import SidebarData from 'src/components/SidebarData';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import logo from 'src/Images/shreeDurga.png';
import { NavLink } from 'react-router-dom';
import {
  DrawerHeader,
  StyledListItemButtonMobile,
  StyledListItemIconMobile,
  StyledListItem,
} from 'src/components/Layout/SidebarStyles';

function MobileSidebar() {
  const [openSidebar, setOpenSidebar] = React.useState(false);

  const filteredSidebarData = SidebarData();

  const toggleMobileSidebar = (open) => () => {
    setOpenSidebar(open);
  };

  return (
    <div>
      <MenuIcon onClick={toggleMobileSidebar(true)} />
      <Drawer
        anchor="left"
        open={openSidebar}
        onClose={toggleMobileSidebar(false)}
        id="drawerMobileView"
      >
        <Box
          onClick={toggleMobileSidebar(false)}
          onKeyDown={toggleMobileSidebar(false)}
        >
          <DrawerHeader className="sidebar-header">
            <Box>
              <img src={logo} height={30} width={30} alt="expound-logo" />
            </Box>
          </DrawerHeader>
          <List>
            {filteredSidebarData.map((text) => (
              <StyledListItem key={text.id} disablePadding>
                <NavLink to={text.url} className="navlink-button">
                  <StyledListItemButtonMobile>
                    <StyledListItemIconMobile>
                      {text.icon}
                    </StyledListItemIconMobile>
                    <ListItemText primary={text.name} />
                  </StyledListItemButtonMobile>
                </NavLink>
              </StyledListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

export default MobileSidebar;
