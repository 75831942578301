import {
  TENANT_LIST,
  CREATE_TENANT,
  ALL_USERS,
  UPDATE_PROFILE,
  CREATE_USER,
} from 'src/redux/Actions/Type';
const initialState = {
  tenantList: [],
  createUser: {},
  createTenant: {},
  allUsers: [],
  updateProfile: {},
};
const TenantReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case TENANT_LIST:
      return {
        ...state,
        tenantList: payload,
      };
    case CREATE_TENANT:
      return {
        ...state,
        createTenant: payload,
      };
    case ALL_USERS:
      return {
        ...state,
        allUsers: payload,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        updateProfile: payload,
      };
    case CREATE_USER:
      return {
        ...state,
        createUser: payload,
      };
    default:
      return state;
  }
};
export default TenantReducer;
