import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers, getTenantUsers } from 'src/redux/Actions/Tenant';
import 'src/style/tenant.css';

export default function Users({
  setSelectedUser,
  toggleShowLeftDetailSidebar,
  showUpdateUserForm,
  toggleRightLayout,
}) {
  const authReducer = useSelector((state) => state.AuthReducer);
  const tenantId = authReducer.userData?.tenant?.id;
  const isSuperAdmin = authReducer.superAdmin;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuperAdmin === true) {
      dispatch(getAllUsers());
    } else {
      dispatch(getTenantUsers(tenantId));
    }
  }, [dispatch]);

  const tenantReducer = useSelector((state) => state.TenantReducer);
  const usersData = tenantReducer?.allUsers;

  const handleCheckboxChange = (userObj) => (e) => {
    if (e.target.checked) {
      setSelectedUser(userObj);
      toggleShowLeftDetailSidebar();
    }
  };

  return (
    <div className="div-flex-column rg-20">
      <div className="div-flex-column">
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xxxl text-bold">All Users</span>
          {!showUpdateUserForm && (
            <button className="btn-black" onClick={toggleRightLayout}>
              Add User
            </button>
          )}
        </div>
        <span className="text-md text-400">{usersData?.length} Users</span>
      </div>
      <div className="po-table">
        <Table hover size="lg" className="table-cell-record">
          <thead>
            <tr>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  ID
                </div>
              </th>
              {isSuperAdmin && (
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-100">
                    Tenant
                  </div>
                </th>
              )}
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  Organisation
                </div>
              </th>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  Name
                </div>
              </th>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  Email
                </div>
              </th>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  User Type
                </div>
              </th>
              {!isSuperAdmin && (
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-100">
                    Stage
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="div-scrollable-details">
            {usersData?.length > 0 ? (
              usersData.map((userObj) => (
                <tr key={userObj.profile_id}>
                  <td className="table-cell-min-width-100">
                    <div className="div-flex-row cg-10 div-align-center">
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        onChange={handleCheckboxChange(userObj)}
                      />
                      <div className="text-md text-400 text-center">
                        #{userObj.profile_id}
                      </div>
                    </div>
                  </td>
                  {isSuperAdmin && (
                    <td className="table-cell-min-width-100">
                      <div className="text-md text-400">
                        {userObj.tenant_name || 'Not Assigned'}
                      </div>
                    </td>
                  )}
                  <td className="table-cell-min-width-100">
                    <div className="text-md text-400">
                      {userObj.assigned_organisations?.name || 'Not Assigned'}
                    </div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md text-400">{userObj.name}</div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md text-400">{userObj.email}</div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md text-400">{userObj.user_type}</div>
                  </td>

                  {!isSuperAdmin && (
                    <td className="table-cell-min-width-100">
                      {userObj.assigned_stages != null ? (
                        <div className="text-md text-400">
                          {userObj.assigned_stages?.map((stage, index) => (
                            <>
                              <span key={stage}>{stage.name}</span>
                              {index < userObj.assigned_stages.length - 1 &&
                                ', '}
                            </>
                          ))}
                        </div>
                      ) : (
                        <div className="text-md text-400">
                          <span>Not Assigned</span>
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan="4">
                  <div className="text-md text-400">No User Created</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
