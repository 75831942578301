import React, { useState } from 'react';
import 'src/style/tenant.css';
import ThreeColLayout from 'src/components/Layout/ThreeColLayout';
import StageList from 'src/components/Stages/StageList';
import CreateStageForm from 'src/components/Stages/CreateStageForm';

export default function StagesManagementLayout() {
  const [showCreateStageForm, setShowCreateStageForm] = useState(false);

  const toggleRightLayout = () => {
    setShowCreateStageForm(!showCreateStageForm);
  };

  const calculateColumnSizes = () => {
    if (showCreateStageForm) {
      return { leftColSize: 0, middleColSize: 8, rightColSize: 4 };
    }
    return { leftColSize: 0, middleColSize: 12, rightColSize: 0 };
  };

  const { leftColSize, middleColSize, rightColSize } = calculateColumnSizes();

  return (
    <ThreeColLayout
      showLeftSidebar={false}
      showMiddleContainer
      showRightSidebar={showCreateStageForm}
      middleContainerContent={
        <StageList
          onToggleShowCreateStageForm={toggleRightLayout}
          StageForm={showCreateStageForm}
          toggleRightLayout={toggleRightLayout}
        />
      }
      rightSidebarContent={
        showCreateStageForm ? (
          <CreateStageForm onClose={toggleRightLayout} />
        ) : null
      }
      showFooter
      onToggleRightLayout={toggleRightLayout}
      leftColSize={leftColSize}
      middleColSize={middleColSize}
      rightColSize={rightColSize}
    />
  );
}
