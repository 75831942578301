import {
  getTargetVsActualForConfigApi,
  getTimelineApi,
  getPurchaseOrderListApi,
  getPoItemsApi,
  skipStageApi,
  moveToNextStageApi,
  getVehicleTrackingDetailApi,
  getStageDataApi,
  getPurchaseOrderApi,
  createVehicleTrackingApi,
  getBarcodeApi,
  getBusinessPartnerListApi,
  getVehicleTrackingReportDataApi,
  getAssignedStagesApi,
  getVehicleTrackingWorkflowsApi,
  getWorkflowStagesForVehicleTrackingApi,
  getTargetVsActualConfigForWorkflowStageApi,
  createTargetVsActualLogsApi,
  getVehicleTrackingListApi,
  getNextAvailableWorkflowStagesApi,
  skipAndMoveVtToAnotherWsApi,
  getVarianceReportDataApi,
  getAvailableMaterialsCodesForOrganisationApi,
} from 'src/api/vehicleTracking';
import {
  GET_TARGET_VS_ACTUAL_FOR_CONFIG,
  GET_TIMELINE,
  GET_PO_LIST,
  GET_PO_ITEMS,
  SKIP_STAGE,
  MOVE_TO_NEXT_STAGE,
  GET_VEHICLE_TRACKING_DETAILS,
  GET_STAGE_DATA,
  GET_PURCHASE_ORDERS,
  CREATE_VEHICLE_TRACKING,
  GET_BARCODE,
  GET_BUSINESS_PARTNER,
  GET_VEHICLE_TRAKING_REPORT_DATA,
  GET_ASSIGNED_STAGES,
  GET_WORKFLOWS,
  GET_WORKFLOW_STAGES_FOR_VEHICLE_TRACKING,
  CLEAR_WORKFLOW_STAGES_FOR_VEHICLE_TRACKING,
  GET_TARGET_VS_ACTUAL_CONFIG_FOR_WORKFLOW_STAGE,
  CREATE_TARGET_VS_ACTUAL_LOGS,
  GET_VEHICLE_TRACKING_LIST,
  GET_NEXT_AVAILABLE_WORKFLOW_STAGES,
  SKIP_AND_MOVE_VT_TO_ANOTHER_WS,
  GET_VARIANCE_REPORT_DATA,
  GET_AVAILABLE_MATERIAL_CODES,
} from 'src/redux/Actions/Type';

export function getPoItems(
  vehicleTrackingNumber,
  poID,
  tenantOrganisationId,
  workflowStageId,
) {
  return (dispatch) => {
    getPoItemsApi(
      vehicleTrackingNumber,
      poID,
      tenantOrganisationId,
      workflowStageId,
    ).then((data) => {
      dispatch({
        type: GET_PO_ITEMS,
        payload: data,
      });
    });
  };
}

export const getTimelineData =
  (vehicleTrackingNumber, tenantOrganisationId) => (dispatch) =>
    getTimelineApi(vehicleTrackingNumber, tenantOrganisationId).then((data) => {
      dispatch({
        type: GET_TIMELINE,
        payload: data,
      });
    });

export function getVehicleTrackingDetail(
  vehicleTrackingNumber,
  tenantOrganisationId,
  workflowStageId,
) {
  return (dispatch) => {
    getVehicleTrackingDetailApi(
      vehicleTrackingNumber,
      tenantOrganisationId,
      workflowStageId,
    ).then((data) => {
      dispatch({
        type: GET_VEHICLE_TRACKING_DETAILS,
        payload: data,
      });
    });
  };
}

export const getTargetVsActualForConfig =
  (vehicleTrackingNumber, configId) => (dispatch) =>
    getTargetVsActualForConfigApi(vehicleTrackingNumber, configId).then(
      (data) => {
        dispatch({
          type: GET_TARGET_VS_ACTUAL_FOR_CONFIG,
          payload: data,
        });
        return Promise.resolve(data);
      },
    );

export const getPurchaseOrderList =
  (vehicleTrackingNumber, tenantOrganisationId) => (dispatch) =>
    getPurchaseOrderListApi(vehicleTrackingNumber, tenantOrganisationId).then(
      (data) => {
        dispatch({
          type: GET_PO_LIST,
          payload: data,
        });
      },
    );

export const skipStage =
  (skipTimeLineStageData, tenantOrganisationId) => (dispatch) =>
    skipStageApi(skipTimeLineStageData, tenantOrganisationId).then((data) => {
      dispatch({
        type: SKIP_STAGE,
        payload: data,
      });
    });

export const moveToNextStage = (moveToNextStageData) => (dispatch) =>
  moveToNextStageApi(moveToNextStageData).then((data) => {
    dispatch({
      type: MOVE_TO_NEXT_STAGE,
      payload: data,
    });
  });

export const getStageData = (stationId, tenantOrganisationId) => (dispatch) =>
  getStageDataApi(stationId, tenantOrganisationId).then((data) => {
    dispatch({
      type: GET_STAGE_DATA,
      payload: data,
    });
  });

export const getPurchaseOrder = (searchData) => (dispatch) =>
  getPurchaseOrderApi(searchData).then((data) => {
    dispatch({
      type: GET_PURCHASE_ORDERS,
      payload: data,
    });
  });

export const createVehicleTracking = (entryData) => (dispatch) =>
  createVehicleTrackingApi(entryData).then((data) => {
    dispatch({
      type: CREATE_VEHICLE_TRACKING,
      payload: data,
    });
    return Promise.resolve(data);
  });

export const getBarcode = (vehicleTrackingNumber) => (dispatch) =>
  getBarcodeApi(vehicleTrackingNumber).then((data) => {
    dispatch({
      type: GET_BARCODE,
      payload: data,
    });
    return Promise.resolve(data);
  });

export const getBusinessPartners = (tenantOrganisationId) => (dispatch) =>
  getBusinessPartnerListApi(tenantOrganisationId).then((data) => {
    dispatch({
      type: GET_BUSINESS_PARTNER,
      payload: data,
    });
    return Promise.resolve(data);
  });

export const getVehicleTrackingReportData =
  (vehicleTrackingNumber, tenantOrganisationId) => (dispatch) =>
    getVehicleTrackingReportDataApi(
      vehicleTrackingNumber,
      tenantOrganisationId,
    ).then((data) => {
      dispatch({
        type: GET_VEHICLE_TRAKING_REPORT_DATA,
        payload: data,
      });
    });

export const getAssignedStages = (profileId) => (dispatch) =>
  getAssignedStagesApi(profileId).then((data) => {
    dispatch({
      type: GET_ASSIGNED_STAGES,
      payload: data,
    });
  });

export const getVehicleTrackingWorkflows =
  (tenantOrganisationId) => (dispatch) =>
    getVehicleTrackingWorkflowsApi(tenantOrganisationId).then((data) => {
      dispatch({
        type: GET_WORKFLOWS,
        payload: data,
      });
    });

export const getWorkflowStagesForVehicleTracking =
  (stageId, inputData) => (dispatch) =>
    getWorkflowStagesForVehicleTrackingApi(stageId, inputData).then((data) => {
      dispatch({
        type: GET_WORKFLOW_STAGES_FOR_VEHICLE_TRACKING,
        payload: data,
      });
    });

export const clearWorkflowStagesForVehicleTracking = () => (dispatch) =>
  dispatch({
    type: CLEAR_WORKFLOW_STAGES_FOR_VEHICLE_TRACKING,
    payload: [],
  });

export const getTargetVsActualConfigForWorkflowStage =
  (workflowStageId, vehicleTrackingNumber) => (dispatch) =>
    getTargetVsActualConfigForWorkflowStageApi(
      workflowStageId,
      vehicleTrackingNumber,
    ).then((data) => {
      dispatch({
        type: GET_TARGET_VS_ACTUAL_CONFIG_FOR_WORKFLOW_STAGE,
        payload: data,
      });
    });

export const createTargetVsActualLogs = (data) => (dispatch) =>
  createTargetVsActualLogsApi(data).then((payloadData) => {
    dispatch({
      type: CREATE_TARGET_VS_ACTUAL_LOGS,
      payload: payloadData,
    });
  });

export function getVehicleTrackingList(
  tenantOrganisationId,
  vehicleTrackingNumber,
  vehicleNumber,
  poNumber,
  startDate,
  endDate,
) {
  return (dispatch) => {
    getVehicleTrackingListApi(
      tenantOrganisationId,
      vehicleTrackingNumber,
      vehicleNumber,
      poNumber,
      startDate,
      endDate,
    ).then((data) => {
      dispatch({
        type: GET_VEHICLE_TRACKING_LIST,
        payload: data,
      });
    });
  };
}

export function getNextAvailableWorkflowStages(
  tenantOrganisationId,
  vehicleTrackingNumber,
) {
  return (dispatch) => {
    getNextAvailableWorkflowStagesApi(
      tenantOrganisationId,
      vehicleTrackingNumber,
    ).then((data) => {
      dispatch({
        type: GET_NEXT_AVAILABLE_WORKFLOW_STAGES,
        payload: data,
      });
    });
  };
}

export function skipAndMoveVtToAnotherWs(
  tenantOrganisationId,
  vehicleTrackingNumber,
  requestedWorkflowStageId,
) {
  return (dispatch) =>
    skipAndMoveVtToAnotherWsApi(
      tenantOrganisationId,
      vehicleTrackingNumber,
      requestedWorkflowStageId,
    ).then((data) => {
      dispatch({
        type: SKIP_AND_MOVE_VT_TO_ANOTHER_WS,
        payload: data,
      });
      return Promise.resolve(data);
    });
}

export function getVarianceReportData(
  tenantOrganisationId,
  vehicleTrackingNumber,
  purchaseOrderNumber,
  materialCode,
  startDate,
  endDate,
) {
  return (dispatch) => {
    getVarianceReportDataApi(
      tenantOrganisationId,
      vehicleTrackingNumber,
      purchaseOrderNumber,
      materialCode,
      startDate,
      endDate,
    ).then((data) => {
      dispatch({
        type: GET_VARIANCE_REPORT_DATA,
        payload: data,
      });
    });
  };
}

export function getAvailableMaterialsCodesForOrganisation(
  tenantOrganisationId,
) {
  return (dispatch) => {
    getAvailableMaterialsCodesForOrganisationApi(tenantOrganisationId).then(
      (data) => {
        dispatch({
          type: GET_AVAILABLE_MATERIAL_CODES,
          payload: data,
        });
      },
    );
  };
}
