import React, { useEffect } from 'react';
import 'src/style/tenant.css';
import { useDispatch, useSelector } from 'react-redux';
import { getTenantList, createUser } from 'src/redux/Actions/Tenant';
import { MdClose } from 'react-icons/md';

export default function CreateUsersForm({ onSubmit }) {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.AuthReducer);
  const isSuperAdmin = authReducer.superAdmin;

  useEffect(() => {
    if (isSuperAdmin === true) {
      dispatch(getTenantList());
    }
  }, [dispatch]);

  const organisationId = authReducer.userData.organisations?.id;
  const tenantId = authReducer.userData.tenant?.id;

  const tenantReducer = useSelector((state) => state.TenantReducer);
  const tenantData = tenantReducer?.tenantList;
  const getAvailableUserTypes = (superAdmin) => {
    let data = [];
    if (superAdmin) {
      data = [
        { id: '1', name: 'Super Admin' },
        { id: '2', name: 'Admin' },
        { id: '3', name: 'User' },
      ];
    } else {
      data = [
        { id: '2', name: 'Admin' },
        { id: '3', name: 'User' },
      ];
    }
    return data;
  };

  const userTypes = getAvailableUserTypes(isSuperAdmin);

  const handleCreateUserFormSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const defaultOrganisationId = isSuperAdmin
      ? data.get('tenant')
      : organisationId;
    const createTenantData = {
      first_name: data.get('firstName'),
      last_name: data.get('lastName'),
      password: data.get('password'),
      email: data.get('email'),
      user_type: data.get('user-type'),
      tenant_organisation_id: defaultOrganisationId || null,
      tenant_id: tenantId,
    };
    dispatch(createUser(createTenantData)).then(() => {
      window.location.reload();
    });
  };

  return (
    <form
      onSubmit={handleCreateUserFormSubmit}
      className="div-flex-column right-form-layout div-space-between"
    >
      <div className="div-flex-column">
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xxxl text-bold">Create User Form</span>
          <MdClose size="18px" onClick={onSubmit} />
        </div>
        {isSuperAdmin === true && (
          <div className="div-flex-column">
            <span className="text-md text-bold">Tenant:</span>
            <select name="tenant" className="modal-form-input">
              <option value="">Select a Tenant</option>
              {tenantData.map((tenant) => (
                <option key={tenant.tenant_id} value={tenant.tenant_id}>
                  {tenant.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="form-body-section div-flex-column rg-20">
          <div className="div-flex-column">
            <span className="text-md text-bold">First Name:</span>
            <input
              name="firstName"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
          <div className="div-flex-column">
            <span className="text-md text-bold">Last Name:</span>
            <input
              name="lastName"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
          <div className="div-flex-column">
            <span className="text-md text-bold">Email:</span>
            <input
              name="email"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
          <div className="div-flex-column">
            <span className="text-md text-bold">User Type:</span>
            <select name="user-type" className="modal-form-input">
              <option value="">Select a Type</option>
              {userTypes.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>
          <div className="div-flex-column">
            <span className="text-md text-bold">Password:</span>
            <input
              name="password"
              className="modal-form-input"
              size="small"
              type="password"
            />
          </div>
        </div>
      </div>
      <div className="div-flex-end">
        <button className="btn-black" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
}
