import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';

export const drawerWidth = 240;

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  boxShadow: 'none',
  background: '#ffffff',
  borderBottom: '1px solid #E3E3E3',
  padding: '5px',
  zIndex: theme.zIndex.drawer + 0,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const StyledAccountIconButton = styled(IconButton, {
  name: 'StyledAccountIconButton',
})(() => ({
  padding: '0%',
}));

export const StyledPopover = () => ({
  padding: '0%',
  marginTop: '15px',
  marginLeft: '0.75px',
  '& .MuiMenuItem-root': {
    typography: 'body2',
    borderRadius: 0.75,
  },
});
