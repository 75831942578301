import React from 'react';
export default function CreateVehicleTrackingLeftSidebar() {
  return (
    <div className="vehicle-entry-component">
      <span className="text-lg text-grey text-bold">Details</span>
      <div className="div-flex-column rg-20">
        <div className="div-flex-column rg-5">
          <span className="text-md text-bold">Driver Name:</span>
          <input
            name="driverName"
            className="modal-form-input"
            size="small"
            type="text"
          />
        </div>
        <div className="div-flex-column rg-5">
          <span className="text-md text-bold">Driver License Number:</span>
          <input
            name="driverLicenseNumber"
            className="modal-form-input"
            size="small"
            type="text"
          />
        </div>
        <div className="div-flex-row cg-10">
          <div className="div-flex-column rg-5">
            <span className="text-md text-bold">Driver License Validity:</span>
            <input
              name="driverLicenseValidity"
              className="modal-form-input"
              type="date"
              id="date"
            />
          </div>
          <div className="div-flex-column rg-5">
            <span className="text-md text-bold">PUC Valid Upto:</span>
            <input
              name="pucValidUpto"
              className="modal-form-input"
              type="date"
              id="date"
            />
          </div>
        </div>
        <div className="div-flex-row cg-10">
          <div className="div-flex-column rg-5">
            <span className="text-md text-bold">Container Number:</span>
            <input
              name="containerNumber"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
          <div className="div-flex-column rg-5">
            <span className="text-md text-bold">Original Invoice:</span>
            <input
              name="originalInvoice"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
        </div>
        <div className="div-flex-row cg-10">
          <div className="div-flex-column rg-5">
            <span className="text-md text-bold">Driver Mobile Number:</span>
            <input
              name="driverMobileNumber"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
          <div className="div-flex-column rg-5">
            <span className="text-md text-bold">EWAY Bill:</span>
            <input
              name="ewayBill"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
        </div>
        <div className="div-flex-row cg-10">
          <div className="div-flex-column rg-5">
            <span className="text-md text-bold">COA:</span>
            <input
              name="coa"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
          <div className="div-flex-column rg-5">
            <span className="text-md text-bold">Weight Slip:</span>
            <input
              name="weightSlip"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
        </div>
        <div className="div-flex-row cg-10">
          <div className="div-flex-column rg-5">
            <span className="text-md text-bold">Remarks:</span>
            <input
              name="remarks"
              className="modal-form-input"
              size="small"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
