import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getVarianceReportData,
  getAvailableMaterialsCodesForOrganisation,
} from 'src/redux/Actions/VehicleTracking';
import 'src/style/vehicleTrackingList.css';

export default function VarianceReport() {
  const dispatch = useDispatch();

  const vehicleTrackingDataReducer = useSelector(
    (state) => state.VehicleTrackingReducer,
  );

  const authReducer = useSelector((state) => state.AuthReducer);
  const organisationId = authReducer.userData.organisations?.id;

  const { varianceReportData } = vehicleTrackingDataReducer;
  const { availableMaterialCodesForOranisation } = vehicleTrackingDataReducer;

  function handleSearch() {
    const vehicleTrackingNumber = document.getElementById(
      'vehicleTrackingNumber',
    ).value;
    const purchaseOrderNumber = document.getElementById(
      'purchaseOrderNumber',
    ).value;
    const startDate = document.getElementById('startDate').value;
    const endDate = document.getElementById('endDate').value;
    const materialCode = document.getElementById('materialCode').value;

    dispatch(
      getVarianceReportData(
        organisationId,
        vehicleTrackingNumber,
        purchaseOrderNumber,
        materialCode,
        startDate,
        endDate,
      ),
    );
  }

  useEffect(() => {
    dispatch(getAvailableMaterialsCodesForOrganisation(organisationId));
  }, []);

  return (
    <div className="page-layout">
      <div className="div-flex-column">
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xxxl text-bold">Variance Report</span>
        </div>
        <hr />
      </div>

      <div className="div-flex-column rg-20">
        <div className="row gx-3">
          <div className="col-12 col-md-4 col-auto mt-1">
            <div className="search-container">
              <input
                id="vehicleTrackingNumber"
                className="report-search-bar text-md w-100"
                type="text"
                placeholder="Vehicle Tracking Number"
              />
            </div>
          </div>

          <div className="col-12 col-md-auto mt-1">
            <div className="search-container">
              <input
                id="purchaseOrderNumber"
                className="report-search-bar text-md w-100"
                type="text"
                placeholder="Purchase Order Number"
              />
            </div>
          </div>

          <div className="col-12 col-md-2 col-auto mt-1">
            <div className="search-container">
              <select
                className="report-search-bar text-md w-100"
                id="materialCode"
              >
                <option value="">Select Material Code</option>
                {availableMaterialCodesForOranisation.map((materialCode) => (
                  <option
                    key={materialCode.id}
                    value={materialCode.material_code}
                  >
                    {materialCode.material_code}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-12 col-md-auto mt-1">
            <div className="search-container">
              <input
                id="startDate"
                className="report-search-bar text-md w-100"
                type="date"
                defaultValue={new Date().toISOString().slice(0, 10)}
              />
            </div>
          </div>

          <div className="col-12 col-md-auto mt-1">
            <div className="search-container">
              <input
                id="endDate"
                className="report-search-bar text-md w-100"
                type="date"
                defaultValue={new Date().toISOString().slice(0, 10)}
              />
            </div>
          </div>

          {/* Search Button */}
          <div className="d-flex col-12 col-md-auto mt-1">
            <button className="btn-black" onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>

        <div className="table-wrapper">
          <Table hover size="lg" className="table-cell-record">
            <thead>
              <tr>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-150">
                    Tracking Number
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-150">
                    Vehicle Number
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-150">
                    Gross Weight
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-150">
                    Tare Weight
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-150">
                    Net Weight
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-150">
                    Supplier Name
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-150">
                    PO Number
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-150">
                    PO Line Item
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-150">
                    PO Weight
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-150">
                    Variance
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-150">
                    Variance %
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-150">
                    Variance Type
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {varianceReportData.length > 0 ? (
                varianceReportData.map((materialCodeObject) => (
                  <React.Fragment key={materialCodeObject.id}>
                    <tr>
                      <td colSpan={12}>
                        <span className="text-md text-400 text-underline">
                          Material Code: {materialCodeObject.material_code}
                        </span>
                      </td>
                    </tr>
                    {materialCodeObject.data.map((vehicleTrackingData) => (
                      <tr key={vehicleTrackingData.vehicle_tracking_number}>
                        <td className="table-cell-min-width-150">
                          <div className="div-flex-row cg-10 div-align-center">
                            <div className="text-md text-400">
                              {vehicleTrackingData.vehicle_tracking_number}
                            </div>
                          </div>
                        </td>
                        <td className="table-cell-min-width-150">
                          <div className="text-md text-400">
                            {vehicleTrackingData.vehicle_number}
                          </div>
                        </td>
                        <td className="table-cell-min-width-150">
                          <div className="text-md text-400">
                            {vehicleTrackingData.gross_weight || 0}
                          </div>
                        </td>
                        <td className="table-cell-min-width-150">
                          <div className="text-md text-400">
                            {vehicleTrackingData.tare_weight || 0}
                          </div>
                        </td>
                        <td className="table-cell-min-width-150">
                          <div className="text-md text-400">
                            {vehicleTrackingData.net_weight || 0}
                          </div>
                        </td>
                        <td className="table-cell-min-width-150">
                          <div className="text-md text-400">
                            {vehicleTrackingData.supplier_name}
                          </div>
                        </td>
                        <td className="table-cell-min-width-150">
                          <div className="text-md text-400">
                            {vehicleTrackingData.purchase_order_numbers}
                          </div>
                        </td>
                        <td className="table-cell-min-width-150">
                          <div className="text-md text-400">
                            <span>
                              {vehicleTrackingData.purchase_order_line_items.join(
                                ', ',
                              )}
                            </span>
                          </div>
                        </td>
                        <td className="table-cell-min-width-150">
                          <div className="text-md text-400">
                            {vehicleTrackingData.purchase_order_weight}
                          </div>
                        </td>
                        <td className="table-cell-min-width-150">
                          <div className="text-md text-400">
                            {vehicleTrackingData.difference}
                          </div>
                        </td>
                        <td className="table-cell-min-width-150">
                          <div className="text-md text-400">
                            {vehicleTrackingData.shortage_excess_percentage}%
                          </div>
                        </td>
                        <td className="table-cell-min-width-150">
                          <div className="text-md text-400">
                            {vehicleTrackingData.status}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={2} className="text-md text-bold">
                        Total:{' '}
                      </td>
                      <td className="text-md text-bold ">
                        {materialCodeObject.total_gross_weight}
                      </td>
                      <td className="text-md text-bold">
                        {materialCodeObject.total_tare_weight}
                      </td>
                      <td colSpan={4} className="text-md text-bold">
                        {materialCodeObject.total_net_weight}
                      </td>
                      <td colSpan={4} className="text-md text-bold">
                        {materialCodeObject.purchase_order_weight}
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <div className="div-flex-row div-align-center">
                  <span className="text-md text-400">No results found</span>
                </div>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
