import React, { useState, useEffect } from 'react';
import 'src/style/stagedashboard.css';
import ThreeColLayout from 'src/components/Layout/ThreeColLayout';
import StageRightSidebar from 'src/components/Stage/StageRightSidebar';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStageData,
  getAssignedStages,
} from 'src/redux/Actions/VehicleTracking';
import StageDashboardMainContent from 'src/components/Stage/StageDashboardMainContent';

export default function StageDashboard() {
  const dispatch = useDispatch();

  const authReducer = useSelector((state) => state.AuthReducer);
  const [selectedStageId, setSelectedStageId] = useState(null);
  const organisationId = authReducer.userData.organisations?.id;
  const profileId = authReducer.userData?.profile_id;

  useEffect(() => {
    dispatch(getAssignedStages(profileId));
  }, [profileId]);

  const vehicleTrackingDataReducer = useSelector(
    (state) => state.VehicleTrackingReducer,
  );

  useEffect(() => {
    if (selectedStageId !== null) {
      dispatch(getStageData(selectedStageId, organisationId));
    } else if (
      selectedStageId === null &&
      vehicleTrackingDataReducer.assignedStages.length > 0
    ) {
      setSelectedStageId(vehicleTrackingDataReducer.assignedStages[0].id);
    }
  }, [dispatch, selectedStageId, vehicleTrackingDataReducer.assignedStages]);

  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const toggleRightSidebarTable = () => {
    setShowRightSidebar(!showRightSidebar);
  };

  const calculateColumnSizes = () => {
    if (showRightSidebar) {
      return { leftColSize: 0, middleColSize: 8, rightColSize: 4 };
    }
    return { leftColSize: 0, middleColSize: 12, rightColSize: 0 };
  };
  const { leftColSize, middleColSize, rightColSize } = calculateColumnSizes();

  return (
    <ThreeColLayout
      showLeftSidebar={false}
      showMiddleContainer
      showRightSidebar={showRightSidebar}
      middleContainerContent={
        <StageDashboardMainContent
          showRightSidebar={showRightSidebar}
          toggleRightSidebarTable={toggleRightSidebarTable}
          setSelectedStageId={setSelectedStageId}
          selectedStageId={selectedStageId}
        />
      }
      rightSidebarContent={
        <StageRightSidebar
          toggleRightSidebarTable={toggleRightSidebarTable}
          selectedStageId={selectedStageId}
        />
      }
      showFooter
      onToggleRightLayout={toggleRightSidebarTable}
      leftColSize={leftColSize}
      middleColSize={middleColSize}
      rightColSize={rightColSize}
    />
  );
}
