import React from 'react';
import { MdClose } from 'react-icons/md';

export default function TenantDetail({ tenant, onClose }) {
  return (
    <div className="tenant-detail-right-container">
      <div className="div-flex-column">
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xxxl text-bold">Tenant Details</span>
          <MdClose size="18px" onClick={onClose} />
        </div>
        <span className="text-md text-400">Tenant #{tenant?.id}</span>
      </div>
      <div className="div-flex-column rg-10">
        <div className="div-flex-column">
          <span className="text-md text-bold">Name-</span>
          <span className="text-md text-400">{tenant?.name}</span>
        </div>
        <div className="div-flex-column">
          <span className="text-md text-bold">Domain-</span>
          <span className="text-md text-400">{tenant?.domain}</span>
        </div>
        <div className="div-flex-column">
          <span className="text-md text-bold">Start Date-</span>
          <span className="text-md text-400">{tenant?.start_date}</span>
        </div>
        <div className="div-flex-column">
          <span className="text-md text-bold">End Date-</span>
          <span className="text-md text-400">{tenant?.end_date}</span>
        </div>
        <div className="div-flex-column">
          <span className="text-md text-bold">Description-</span>
          <span className="text-md text-400">{tenant?.description}</span>
        </div>
      </div>
    </div>
  );
}
