import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import {
  getPurchaseOrderList,
  getPoItems,
  skipStage,
  getVehicleTrackingDetail,
  moveToNextStage,
  getBarcode,
  getTargetVsActualConfigForWorkflowStage,
  getNextAvailableWorkflowStages,
  skipAndMoveVtToAnotherWs,
} from 'src/redux/Actions/VehicleTracking';
import { PiTruckDuotone } from 'react-icons/pi';
import { TbFileBarcode, TbTruckLoading } from 'react-icons/tb';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { LiaTruckLoadingSolid } from 'react-icons/lia';
import { GoDotFill } from 'react-icons/go';
import { PrintBarcodeComponent } from 'src/utils/PrintBarcodeComponent';
import Select, { components } from 'react-select';

function CustomWorkflowOptions(props) {
  return (
    <components.Option {...props}>
      <span>{props.data.label}</span>&nbsp;
      {props.data.mandatory ? (
        <span className="bg-red red-pill text-md">Mandatory</span>
      ) : (
        <span className="bg-green green-pill text-md">Optional</span>
      )}
    </components.Option>
  );
}

export default function TargetVsActualMainContent({ toggleForm, isFormOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workflowStageId, vehicleTrackingNumber } = useParams();
  const authReducer = useSelector((state) => state.AuthReducer);
  const organisationId = authReducer.userData.organisations?.id;
  const [selectedPoIds, setSelectedPoIds] = useState([]);
  const [actionButtonAnimation, setActionButtonAnimation] = useState(true);
  const isOrganisationAdmin = authReducer.organisationAdmin;
  const [selectedNextWorkflowStage, setSelectedNextWorkflowStage] =
    useState(null);

  // Hooks
  useEffect(() => {
    dispatch(
      getVehicleTrackingDetail(
        vehicleTrackingNumber,
        organisationId,
        workflowStageId,
      ),
    );
    dispatch(getPurchaseOrderList(vehicleTrackingNumber, organisationId));
    dispatch(
      getTargetVsActualConfigForWorkflowStage(
        workflowStageId,
        vehicleTrackingNumber,
      ),
    );
  }, [dispatch]);

  //
  useEffect(() => {
    dispatch(
      getPoItems(
        vehicleTrackingNumber,
        selectedPoIds,
        organisationId,
        workflowStageId,
      ),
    );
  }, [selectedPoIds]);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setActionButtonAnimation(false);
    }, 8000);

    return () => clearTimeout(animationTimeout);
  }, []);

  // Data
  const vehicleTrackingDataReducer = useSelector(
    (state) => state?.VehicleTrackingReducer,
  );
  const requestedStageExit =
    vehicleTrackingDataReducer?.vehicleTrackingDetails?.requestedStage
      ?.exit_log_exist;
  const requestedStageEntry =
    vehicleTrackingDataReducer?.vehicleTrackingDetails?.requestedStage
      ?.entry_log_exist;
  const requestedStage =
    vehicleTrackingDataReducer?.vehicleTrackingDetails?.requestedStage;

  const handleSkipStage = () => {
    const skipStageRequestData = {
      vehicleTrackingNumber,
      workflowStageId: requestedStage?.workflow_stage_id,
    };
    dispatch(skipStage(skipStageRequestData, organisationId)).then(() => {
      navigate(`/stations/`);
    });
  };

  const handlePocheckboxOnchange = (e) => {
    const poId = e.target.value;
    setSelectedPoIds((prevSelectedPoIds) =>
      e.target.checked
        ? [...prevSelectedPoIds, poId]
        : prevSelectedPoIds.filter((id) => id !== poId),
    );
  };

  const poIdData =
    vehicleTrackingDataReducer?.poDetailData?.purchaseOrderDetails;

  const poItemData = vehicleTrackingDataReducer?.poItems;

  const isTargetVsActual =
    vehicleTrackingDataReducer.vehicleTrackingDetails?.targetVsActualConfig;

  const logType =
    requestedStage?.entry_log_exist && !requestedStage?.completed ? '2' : '1';

  const handleMoveToNextStageWithoutTargetVsActualSubmit = (e) => {
    e.preventDefault();

    const data = {
      tenant_organisation_id: organisationId,
      vehicle_tracking_number: vehicleTrackingNumber,
      workflow_stage_id: requestedStage?.workflow_stage_id,
      log_type: logType,
      target_vs_actual: null,
    };
    dispatch(moveToNextStage(data)).then(() => {
      if (data.log_type === '1' || requestedStage.allow_next_stage_selection) {
        window.location.reload();
      } else {
        navigate(`/stations/`);
      }
    });
  };

  const markExit = !requestedStageExit && requestedStageEntry;
  const userName = authReducer.userData.username;

  const handlePrint = () => {
    dispatch(getBarcode(vehicleTrackingNumber)).then((barcodeData) => {
      PrintBarcodeComponent(barcodeData, userName);
    });
  };

  const vehicleTrackingData =
    vehicleTrackingDataReducer?.vehicleTrackingDetails;
  const showMoveToNextStageDropdown = () => {
    if (
      requestedStage?.workflow_stage_id ===
        vehicleTrackingData.currentWorkflowStageId &&
      requestedStage?.allow_next_stage_selection === true &&
      vehicleTrackingData?.vehicleTrackingCompleted === true
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // fetches next available states to skip
    if (
      vehicleTrackingData?.currentWorkflowStageId &&
      requestedStage?.workflow_stage_id &&
      showMoveToNextStageDropdown()
    ) {
      dispatch(
        getNextAvailableWorkflowStages(organisationId, vehicleTrackingNumber),
      );
    }
  }, [
    vehicleTrackingData?.currentWorkflowStageId,
    requestedStage?.workflow_stage_id,
    vehicleTrackingData?.vehicleTrackingCompleted,
    dispatch,
    organisationId,
    vehicleTrackingNumber,
  ]);

  const handleSendToNextAvailableWsFormSubmit = (e) => {
    e.preventDefault();
    const nextWsStageId = selectedNextWorkflowStage.value;

    dispatch(
      skipAndMoveVtToAnotherWs(
        organisationId,
        vehicleTrackingNumber,
        nextWsStageId,
      ),
    ).then(() => {
      navigate(`/stations/`);
    });
  };

  const handleWorkflowStageDropdownChange = (selectedOption) => {
    setSelectedNextWorkflowStage(selectedOption);
  };

  const workflowDropDownInlineStyles = () => ({
    indicatorSeparator: () => ({}),
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? 'lightgray' : 'inherit',
      backgroundColor: state.isSelected ? '#e6f5ff' : 'white',
      fontSize: 14,
    }),
    control: (base) => ({
      ...base,
      fontSize: 12,
      maxHeight: '30px',
      minHeight: '30px',
    }),
    valueContainer: (base) => ({
      ...base,
      marginBottom: 'auto',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      fontSize: 12,
      padding: '0px 8px',
    }),
  });

  const renderMoveToNextStageDropdown = () => {
    if (showMoveToNextStageDropdown()) {
      let shouldDisable = false;

      const options =
        vehicleTrackingDataReducer?.vehicleTrackingWorkflowStagesData?.nextAvailableWorkflowStages?.map(
          (workflowStage, index) => {
            const option = {
              value: workflowStage.id,
              label: workflowStage.name,
              mandatory: workflowStage.mandatory,
              isDisabled: shouldDisable || index === 0,
            };

            if (workflowStage.mandatory && !shouldDisable) {
              shouldDisable = true;
            }
            return option;
          },
        );

      return (
        <div className="alert alert-warning" role="alert">
          <span>Want to send to different next stage?</span>
          <hr />
          <form
            className="inline-move-to-another-ws-form"
            onSubmit={handleSendToNextAvailableWsFormSubmit}
          >
            <Select
              className="react-select-container w-25 custom-workflow-stages-select-dropdown"
              classNamePrefix="custom-select"
              id="workflowStage"
              styles={workflowDropDownInlineStyles()}
              options={options}
              value={selectedNextWorkflowStage}
              onChange={handleWorkflowStageDropdownChange}
              isOptionDisabled={(option) => option.isDisabled}
              placeholder="Select Next Stage"
              components={{ Option: CustomWorkflowOptions }}
              required
            />
            <button
              className={selectedNextWorkflowStage ? 'btn-black' : 'btn-grey'}
              type="submit"
              disabled={!selectedNextWorkflowStage}
            >
              Move to selected stage
            </button>
          </form>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="div-flex-column page-container rg-10">
      <div className="target-vs-actual-form-header">
        <div className=" div-space-between">
          <div className="div-flex-row cg-20">
            <div className="div-flex-row cg-5 div-align-center bg-blue blue-pill">
              <PiTruckDuotone size="18px" />
              <span className="text-lg">
                {
                  vehicleTrackingDataReducer?.vehicleTrackingDetails
                    ?.vehicleNumber
                }
              </span>
            </div>
            {!requestedStage?.completed &&
              (markExit ? (
                <div className="div-flex-row cg-5 div-align-center bg-yellow yellow-pill">
                  <LiaTruckLoadingSolid size="18px" />
                  <span className="text-lg">
                    {requestedStage?.workflow_stage_name}
                  </span>
                </div>
              ) : (
                <div className="div-flex-row cg-5 div-align-center bg-yellow yellow-pill">
                  <TbTruckLoading strokeWidth="1px" size="18px" />
                  <span className="text-lg">
                    {requestedStage?.workflow_stage_name}
                  </span>
                </div>
              ))}
            {!requestedStage?.completed &&
              (markExit ? (
                <div className="div-flex-row cg-5 div-align-center red-pill bg-red">
                  <GoDotFill
                    size="18px"
                    color="#ff9898"
                    className="pill-animation"
                  />
                  <span className="text-lg">Awaiting Exit</span>
                </div>
              ) : (
                <div className="div-flex-row cg-5 div-align-center green-pill bg-green">
                  <GoDotFill
                    size="18px"
                    color="#7eff8b"
                    className="pill-animation"
                  />
                  <span className="text-lg">Awaiting Entry</span>
                </div>
              ))}
            <TbFileBarcode
              size="30px"
              color="#212f3c"
              className={`${actionButtonAnimation ? 'print-button' : ''}`}
              onClick={handlePrint}
            />
          </div>
          <div className="div-flex-row cg-10">
            {!isFormOpen &&
              requestedStageEntry &&
              vehicleTrackingDataReducer.targetVsActualConfig.map((config) => (
                <button
                  className={
                    config.completed ? 'btn btn-sm' : 'btn-sm btn-black'
                  }
                  key={config.id}
                  onClick={() => toggleForm(config)}
                  disabled={config.completed}
                >
                  <span>{config.title}</span>
                </button>
              ))}
            {/* TODO: remove this, ideally we should have different row for all target vs actual */}
            {!isFormOpen && requestedStageEntry ? <>&nbsp; | &nbsp;</> : null}
            {requestedStage?.completed && (
              <Link to={`/report/${vehicleTrackingNumber}`}>
                <button
                  className={`btn-black ${actionButtonAnimation ? 'btn-black-animation' : ''}`}
                >
                  View Report
                </button>
              </Link>
            )}
            {!isFormOpen && !requestedStage?.completed && (
              <div className="div-flex-row cg-10">
                {requestedStageEntry && (
                  <button
                    className={`btn-black ${actionButtonAnimation ? 'btn-black-animation' : ''}`}
                    onClick={
                      isTargetVsActual
                        ? toggleForm
                        : handleMoveToNextStageWithoutTargetVsActualSubmit
                    }
                  >
                    <span>Exit {requestedStage?.workflow_stage_name}</span>
                  </button>
                )}
                {!requestedStageEntry && !requestedStageExit && (
                  <>
                    {isOrganisationAdmin && (
                      <button className="btn-black" onClick={handleSkipStage}>
                        <span>Skip</span>
                      </button>
                    )}
                    <button
                      className={`btn-black ${actionButtonAnimation ? 'btn-black-animation' : ''}`}
                      onClick={
                        isTargetVsActual
                          ? toggleForm
                          : handleMoveToNextStageWithoutTargetVsActualSubmit
                      }
                    >
                      <span>Enter {requestedStage?.workflow_stage_name}</span>
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="div-flex-column rg-10 page-container">
        {renderMoveToNextStageDropdown()}
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xl text-bold">Purchase Order</span>
          <span className="text-md">{poIdData?.length} Purchase Order</span>
        </div>
        <div className="responsive-table-container">
          <Table hover size="lg" className="table-cell-record">
            <thead>
              <tr>
                <th
                  className="bg-grey form-table-width-30"
                  aria-label="PO check box"
                />
                <th className="bg-grey table-cell-min-width-100">
                  <div className="text-lg text-bold">PO Number</div>
                </th>
                <th className="bg-grey table-cell-min-width-100">
                  <div className="text-lg text-bold">Amount</div>
                </th>
                <th className="bg-grey table-cell-min-width-100">
                  <div className="text-lg text-bold">Delivery Date</div>
                </th>
                <th className="bg-grey table-cell-min-width-100">
                  <div className="text-lg text-bold">Vendor Name</div>
                </th>
                <th className="bg-grey table-cell-min-width-100">
                  <div className="text-lg text-bold">Status</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {poIdData?.map((poData) => (
                <tr key={poData?.id}>
                  <td className="form-table-width-30">
                    <input
                      type="checkbox"
                      name="poCheckBox"
                      value={poData?.id}
                      onChange={handlePocheckboxOnchange}
                    />
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md">{poData?.po_number}</div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md ">{poData?.amount}</div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md ">{poData?.delivery_date}</div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md ">{poData?.vendor_name}</div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <span
                      className={
                        poData?.status === 'cancelled'
                          ? 'text-md'
                          : 'text-md green-pill bg-green'
                      }
                    >
                      {poData?.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xl text-bold">Items</span>
          <span className="text-md">{poItemData?.length} Items</span>
        </div>
        <div className="responsive-table-container">
          <Table hover size="lg" className="table-cell-record">
            <thead>
              <tr>
                <th className="bg-grey table-cell-min-width-100">
                  <div className="text-lg text-bold">PO Number</div>
                </th>
                <th className="bg-grey table-cell-min-width-100">
                  <div className="text-lg text-bold">Item Name</div>
                </th>
                <th className="bg-grey table-cell-min-width-100">
                  <div className="text-lg text-bold">Material Code</div>
                </th>
                <th className="bg-grey table-cell-min-width-100">
                  <div className="text-lg text-bold">Quantity</div>
                </th>
                {poItemData.some((item) => item.show_item_price) && (
                  <th className="bg-grey table-cell-min-width-100">
                    <div className="text-lg text-bold">Price Per Item</div>
                  </th>
                )}
                <th className="bg-grey table-cell-min-width-100">
                  <div className="text-lg text-bold">Weight Per Item</div>
                </th>
                <th className="bg-grey table-cell-min-width-100">
                  <div className="text-lg text-bold">Plant</div>
                </th>
              </tr>
            </thead>
            <tbody className="tableCardRow">
              {poItemData?.map((itemData) => (
                <tr key={itemData?.material_code}>
                  <td className="table-cell-min-width-100">
                    {itemData?.po_number}
                  </td>
                  <td className="table-cell-min-width-100">{itemData?.name}</td>
                  <td className="table-cell-min-width-100">
                    {itemData?.material_code}
                  </td>
                  <td className="table-cell-min-width-100">
                    {itemData?.quantity}
                  </td>
                  {itemData.show_item_price && (
                    <td className="table-cell-min-width-100">
                      {itemData?.per_unit_price}
                    </td>
                  )}
                  <td className="table-cell-min-width-100">
                    {itemData?.per_unit_weight}
                  </td>
                  <td className="table-cell-min-width-100">
                    {itemData?.plant}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
