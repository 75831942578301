import React, { useState } from 'react';
import 'src/style/tenant.css';
import ThreeColLayout from 'src/components/Layout/ThreeColLayout';
import Users from 'src/components/UserManagement/Users';
import UserDetails from 'src/components/UserManagement/UserDetails';
import CreateUsersForm from 'src/components/UserManagement/CreateUsersForm';

export default function UserManagementLayout() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUpdateUserForm, setShowUpdateUserForm] = useState(false);
  const [showLeftDetailSidebar, setShowLeftDetailSidebar] = useState(false);

  const toggleShowLeftDetailSidebar = () => {
    setShowLeftDetailSidebar(true);
  };

  const handleLeftDetailSidebar = () => {
    setShowLeftDetailSidebar(false);
    setSelectedUser(null);
  };

  const toggleRightLayout = () => {
    setShowUpdateUserForm(!showUpdateUserForm);
  };

  const calculateColumnSizes = () => {
    if (showLeftDetailSidebar && showUpdateUserForm) {
      return { leftColSize: 3, middleColSize: 6, rightColSize: 3 };
    }
    if (showLeftDetailSidebar) {
      return { leftColSize: 3, middleColSize: 9, rightColSize: 0 };
    }
    if (showUpdateUserForm) {
      return { leftColSize: 0, middleColSize: 8, rightColSize: 4 };
    }
    return { leftColSize: 0, middleColSize: 12, rightColSize: 0 };
  };

  const { leftColSize, middleColSize, rightColSize } = calculateColumnSizes();

  return (
    <ThreeColLayout
      showLeftSidebar={showLeftDetailSidebar}
      showMiddleContainer
      showRightSidebar={showUpdateUserForm}
      leftSidebarContent={
        showLeftDetailSidebar ? (
          <UserDetails onClose={handleLeftDetailSidebar} user={selectedUser} />
        ) : null
      }
      middleContainerContent={
        <Users
          setSelectedUser={setSelectedUser}
          toggleShowLeftDetailSidebar={toggleShowLeftDetailSidebar}
          showUpdateUserForm={showUpdateUserForm}
          toggleRightLayout={toggleRightLayout}
        />
      }
      rightSidebarContent={
        showUpdateUserForm ? (
          <CreateUsersForm onSubmit={toggleRightLayout} />
        ) : null
      }
      showFooter
      onToggleLeftLayout={handleLeftDetailSidebar}
      onToggleRightLayout={toggleRightLayout}
      leftColSize={leftColSize}
      middleColSize={middleColSize}
      rightColSize={rightColSize}
    />
  );
}
