import React from 'react';
import ReactDOM from 'react-dom/client';
import 'src/index.css';
import App from 'src/App';
import store from 'src/redux/store';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'src/style/global.css';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <div className="main">
    <Provider store={store}>
      <App />
    </Provider>
  </div>,
);
