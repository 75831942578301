export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS';
export const CREATE_VEHICLE_TRACKING = 'CREATE_VEHICLE_TRACKING';

export const API_FAILED = 'API_FAILED';

export const GET_VEHICLE_TRAKING_REPORT_DATA =
  'GET_VEHICLE_TRAKING_REPORT_DATA';
export const GET_TARGET_VS_ACTUAL_FOR_CONFIG =
  'GET_TARGET_VS_ACTUAL_FOR_CONFIG';
export const GET_TIMELINE = 'GET_TIMELINE';
export const GET_PO_LIST = 'GET_PO_LIST';
export const GET_PO_ITEMS = 'GET_PO_ITEMS';
export const SKIP_STAGE = 'SKIP_STAGE';
export const MOVE_TO_NEXT_STAGE = 'MOVE_TO_NEXT_STAGE';
export const GET_STAGE_DATA = 'GET_STAGE_DATA';

export const TENANT_LIST = 'TENANT_LIST';
export const CREATE_TENANT = 'CREATE_TENANT';
export const STAGES_LIST = 'STAGES_LIST';
export const CREATE_STAGE = 'CREATE_STAGES';
export const CREATE_ORGANISATION = 'CREATE_ORGANISATION';
export const ORGANISATION_LIST = 'ORGANISATION_LIST';
export const ASSIGN_STAGE = 'ASSIGN_STAGE';
export const ALL_USERS = 'ALL_USERS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CREATE_USER = 'CREATE_USER';
export const GET_VEHICLE_TRACKING_DETAILS = 'GET_VEHICLE_TRACKING_DETAILS';
export const GET_BARCODE = 'GET_BARCODE';
export const GET_BUSINESS_PARTNER = 'GET_BUSINESS_PARTNER';
export const GET_ASSIGNED_STAGES = 'GET_ASSIGNED_STAGES';
export const GET_WORKFLOWS = 'GET_WORKFLOWS';
export const GET_WORKFLOW_STAGES_FOR_VEHICLE_TRACKING =
  'GET_WORKFLOW_STAGES_FOR_VEHICLE_TRACKING';
export const CLEAR_WORKFLOW_STAGES_FOR_VEHICLE_TRACKING =
  'CLEAR_WORKFLOW_STAGES_FOR_VEHICLE_TRACKING';
export const GET_TARGET_VS_ACTUAL_CONFIG_FOR_WORKFLOW_STAGE =
  'GET_TARGET_VS_ACTUAL_CONFIG_FOR_WORKFLOW_STAGE';
export const CREATE_TARGET_VS_ACTUAL_LOGS = 'CREATE_TARGET_VS_ACTUAL_LOGS';
export const GET_VEHICLE_TRACKING_LIST = 'GET_VEHICLE_TRACKING_LIST';
export const GET_NEXT_AVAILABLE_WORKFLOW_STAGES =
  'GET_NEXT_AVAILABLE_WORKFLOW_STAGES';
export const SKIP_AND_MOVE_VT_TO_ANOTHER_WS = 'SKIP_AND_MOVE_VT_TO_ANOTHER_WS';
export const GET_VARIANCE_REPORT_DATA = 'GET_VARIANCE_REPORT_DATA';
export const GET_AVAILABLE_MATERIAL_CODES = 'GET_AVAILABLE_MATERIAL_CODES';
