import React, { useState } from 'react';
import 'src/style/login.css';
import { Row, Col, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GoBrowser, GoGraph, GoLocation } from 'react-icons/go';
import Logo from 'src/Images/E-Track-logo.png';
import { login } from 'src/redux/Actions/Auth';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const handleSubmitLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.currentTarget);
    const userdata = {
      username: data.get('username'),
      password: data.get('password'),
    };

    dispatch(login(userdata))
      .then((response) => {
        if (response && response.super_admin) {
          navigate('/dashboard/');
        } else if (response && response.organisation_admin) {
          navigate('/dashboard/');
        } else if (response && response.normal_user) {
          navigate('/stations/');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Row className="login-container">
      <Col className="login-left-section" xxl={7} xl={7} lg={7}>
        <div className="left-content">
          <div className="heading-title">
            <span>E - Track</span>
          </div>
          <div className="heading-main">
            <span>
              Helping&nbsp;
              <span className="text-underline-blue">Enhance</span> and
            </span>
          </div>
          <div className="heading-main">
            <span>
              <span className="text-underline-red">Optimise</span>
              &nbsp;factory operations
            </span>
          </div>
          <div className="heading-body">
            <GoBrowser className="bullet-icon" />
            <span>Real time monitoring</span>
          </div>
          <div className="heading-body">
            <GoLocation className="bullet-icon" />
            <span>Accurate & Scalable Tracking</span>
          </div>
          <div className="heading-body">
            <GoGraph className="bullet-icon" />
            <span>Enhances Analytics & Efficiency</span>
          </div>
        </div>
      </Col>
      <Col className="login-right-section" xxl={5} xl={5} lg={5}>
        <div className="right-content">
          <div className="div-flex-row cg-10 div-align-center">
            <img
              src={Logo}
              alt="logo"
              className="logo"
              style={{ width: '50px', height: '40px' }}
            />
            <span className="text-xl">Welcome Back!</span>
          </div>
          <div className="heading-main">
            <span>Login to your account</span>
          </div>
          <div className="heading-body">
            <span>It&apos;s nice to see you again. Ready to track?</span>
          </div>
          <p></p>
          <Form onSubmit={handleSubmitLogin}>
            <Form.Group>
              <input
                type="text"
                name="username"
                className="login-form-input"
                placeholder="Your username or email"
              />
            </Form.Group>
            <Form.Group>
              <input
                type="password"
                name="password"
                className="login-form-input"
                placeholder="Your password"
              />
            </Form.Group>
            <button
              type="submit"
              className="login-button text-center"
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="loader"></span>
              ) : (
                <span>Log In</span>
              )}
            </button>
          </Form>
        </div>
      </Col>
    </Row>
  );
}
