import React from 'react';
import { createRoot } from 'react-dom/client';
import logo from 'src/Images/shreeDurga.png';

export function PrintBarcodeComponent(barcodeData, userName) {
  const width = '100mm';
  const height = '75mm';

  const iframe = document.createElement('iframe');
  document.body.appendChild(iframe);
  iframe.style.position = 'absolute';
  iframe.style.width = '0px';
  iframe.style.height = '0px';
  iframe.style.border = 'none';

  const doc = iframe.contentWindow.document;
  doc.open();
  doc.write(`
    <html>
      <head>
        <style>
          @page {
            size: ${width} ${height};
            margin: 0;
          }
          #print-content {
            width: ${width};
            height: ${height};
            margin: 0;
            padding: 0;
          }
          .div-space-between {
            justify-content: space-between;
          }
          .barcode-sticker {
            height: calc(${height} - 10mm);;
            width: calc(${width} - 5mm);;
            border: 1px dotted #9e9e9e;
            border-radius: 4px;
            padding: 10px;
          }
          .div-flex-column {
            flex-direction: column;
          }
          .div-align-center {
            align-items: center;
          }
          .div-flex-row,
          .div-flex-column,
          .div-align-center,
          .div-space-between,
          .div-flex-row-column {
            display: flex;
          }
          .div-justify-center {
            display: flex;
            justify-content: center;
          }
          .div-flex-row {
            flex-direction: row;
          }
          .cg-10 {
            column-gap: 10px;
          }
          .cg-20 {
            column-gap: 20px;
          }
          .rg-10 {
            row-gap: 10px;
          }
          .text-xl {
            font-size: 18px;
            font-family: 'Noto Sans', sans-serif;
          }
          .text-md {
            font-size: 14px;
            font-family: 'Noto Sans', sans-serif;
          }
          .rg-5 {
            row-gap: 5px;
          }
          .cg-5 {
            column-gap: 5px;
          }
          .text-bold {
            font-weight: 600;
          }
          .header-hr {
            border-bottom: 1px solid rgba(145, 158, 171, 0.3);
          }
        </style>
      </head>
      <body>
        <div id="print-content"></div>
      </body>
    </html>
  `);
  doc.close();

  const root = createRoot(doc.getElementById('print-content'));
  root.render(
    <div style={{ width, height, boxSizing: 'border-box' }}>
      <div className="barcode-sticker">
        <div className="div-flex-column rg-5">
          <div>
            <div className="div-flex-row cg-5 div-align-center">
              <img src={logo} alt="" height="35px" width="35px" />
              <span className="text-xl text-bold">
                {barcodeData.organisationName}
              </span>
            </div>
          </div>
          <div className=" div-flex-row div-align-center div-space-between">
            <div className="div-flex-row cg-5">
              <span className="text-md text-bold">Plant:</span>
              <span className="text-md">{barcodeData.plant}</span>
            </div>
            <div className="div-flex-row cg-5">
              <span className="text-md text-bold">Party:</span>
              <span className="text-md">{barcodeData.businessPartnerName}</span>
            </div>
          </div>
          <div className=" div-flex-row div-align-center div-space-between">
            <div className="div-flex-row cg-5">
              <span className="text-md text-bold">Type:</span>
              <span className="text-md">Inward</span>
            </div>
            <div className="div-flex-row cg-5">
              <span className="text-md text-bold">Truck No: </span>
              <span className="text-md">{barcodeData.vehicleNumber}</span>
            </div>
          </div>
          <div className="div-flex-column rg-10">
            <div className="div-flex-row cg-10">
              <span className="text-md text-bold">Number:</span>
              <span className="text-md">
                {barcodeData.vehicleTrackingNumber}
              </span>
            </div>
            <div className="div-justify-center">
              <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  barcodeData.barcodeSvg,
                )}`}
                alt=""
                width="350px"
                height="100px"
              />
            </div>
          </div>
          <div className="header-hr" />
          <div className="div-flex-row div-space-between">
            <div className="div-flex-row cg-5">
              <span className="text-md text-bold">Logistic Officer :</span>
              <span className="text-md">{userName}</span>
            </div>
            <div className="div-flex-row cg-5">
              <span className="text-md">{barcodeData.created}</span>
            </div>
          </div>
        </div>
      </div>
    </div>,
  );

  iframe.onload = () => {
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    document.body.removeChild(iframe);
  };
}
