import { LOGIN, LOGOUT } from 'src/redux/Actions/Type';

const token = localStorage.getItem('token');
const userData = token ? JSON.parse(localStorage.getItem('userData')) : null;
const superAdmin = token
  ? localStorage.getItem('superAdmin') === 'true'
  : false;
const organisationAdmin = token
  ? localStorage.getItem('organisationAdmin') === 'true'
  : false;
const normalUser = token
  ? localStorage.getItem('normalUser') === 'true'
  : false;
const firstStageAssigned = token
  ? localStorage.getItem('showVehicleTrackingCreate') === 'true'
  : false;
const getInitialState = (isToken) => {
  let initialState;
  if (isToken) {
    initialState = {
      isLoggedIn: true,
      token,
      userData,
      superAdmin,
      organisationAdmin,
      normalUser,
      firstStageAssigned,
    };
  } else {
    initialState = {
      isLoggedIn: false,
      token: null,
      userData: null,
      superAdmin: false,
      organisationAdmin: false,
      normalUser: false,
      firstStageAssigned: false,
    };
  }
  return initialState;
};
const initialState = getInitialState(token);

const auth = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        token: payload.token,
        userData: payload.userdata,
        superAdmin: payload.superAdmin,
        organisationAdmin: payload.organisationAdmin,
        normalUser: payload.normalUser,
        firstStageAssigned: payload.firstStageAssigned,
      };

    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        token: null,
        userData: null,
        superAdmin: false,
        organisationAdmin: false,
        normalUser: false,
      };

    default:
      return state;
  }
};

export default auth;
