import config from 'src/config/config';
import api from 'src/api/interceptor';

export function getStageDataApi(stationId, tenantOrganisationId) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/stage-dashboard/?stage_id=${stationId}&tenant_organisation_id=${tenantOrganisationId}`,
    )
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
}

export function getTargetVsActualForConfigApi(vehicleTrackingNumber, configId) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-target-vs-actual/?vehicle_tracking_number=${vehicleTrackingNumber}&config_id=${configId}`,
    )
    .then((res) => Promise.resolve(res));
}

export function getTimelineApi(vehicleTrackingNumber, tenantOrganisationId) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-timeline/?vehicle_tracking_number=${vehicleTrackingNumber}&tenant_organisation=${tenantOrganisationId}`,
    )
    .then((res) => Promise.resolve(res));
}

export function getVehicleTrackingDetailApi(
  vehicleTrackingNumber,
  tenantOrganisationId,
  workflowStageId,
) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-vehicle-tracking-details/?vehicle_tracking_number=${vehicleTrackingNumber}&tenant_organisation=${tenantOrganisationId}&workflow_stage_id=${workflowStageId}`,
    )
    .then((res) => Promise.resolve(res));
}

export function getPurchaseOrderListApi(
  vehicleTrackingNumber,
  tenantOrganisationId,
) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-purchase-orders-for-vehicle-tracking/?vehicle_tracking_number=${vehicleTrackingNumber}&tenant_organisation_id=${tenantOrganisationId}`,
    )
    .then((res) => Promise.resolve(res));
}

export function getPoItemsApi(
  vehicleTrackingNumber,
  poIds,
  tenantOrganisationId,
  workflowStageId,
) {
  const poId = Array.isArray(poIds)
    ? poIds.map((id) => `po_ids=${id}`).join('&')
    : '';
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-po-item-for-vehicle-tracking/?tenant_organisation_id=${tenantOrganisationId}&vehicle_tracking_number=${vehicleTrackingNumber}&${poId}&workflow_stage_id=${workflowStageId}`,
    )
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
}

export function skipStageApi(data, tenantOrganisationId) {
  return api
    .get(`${config.test_url}api/vehicle-tracking/skip-stage/`, {
      params: {
        vehicle_tracking_number: data?.vehicleTrackingNumber,
        workflow_stage_id: data?.workflowStageId,
        tenant_organisation: tenantOrganisationId,
      },
    })
    .then((res) => Promise.resolve(res));
}

export function moveToNextStageApi(data) {
  return api
    .post(
      `${config.test_url}api/vehicle-tracking/move-vehicle-to-next-stage/`,
      data,
    )
    .then((res) => Promise.resolve(res));
}

export function getBusinessPartnerListApi(tenantOrganisationId) {
  return api
    .get(
      `${config.test_url}api/core/get-business-partners/?tenant_organisation_id=${tenantOrganisationId}`,
    )
    .then((res) => Promise.resolve(res));
}

export function getPurchaseOrderApi(searchData) {
  return api
    .get(
      `${config.test_url}api/procurement/get-purchase-orders/?supplier_name=${searchData?.supplier}&tenant_organisation_id=${searchData?.tenant_organisation_id}${
        searchData?.poID ? `&po_number_filter=${searchData?.poID}` : ''
      }`,
    )
    .then((res) => Promise.resolve(res));
}

export function createVehicleTrackingApi(data) {
  return api
    .post(`${config.test_url}api/vehicle-tracking/create/`, data)
    .then((res) => Promise.resolve(res));
}

export function getBarcodeApi(vehicleTrackingNumber) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-barcode/?vehicle_tracking_number=${vehicleTrackingNumber}`,
    )
    .then((res) => Promise.resolve(res));
}

export function getVehicleTrackingReportDataApi(
  vehicleTrackingNumber,
  tenantOrganisationId,
) {
  return api
    .get(`${config.test_url}api/vehicle-tracking/get-report-data/`, {
      params: {
        vehicle_tracking_number: vehicleTrackingNumber,
        tenant_organisation_id: tenantOrganisationId,
      },
    })
    .then((res) => Promise.resolve(res));
}

export function getAssignedStagesApi(profileId) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-stages-assigned-for-profile/?profile_id=${profileId}`,
    )
    .then((res) => Promise.resolve(res));
}

export function getVehicleTrackingWorkflowsApi(tenantOrganisationId) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-workflows/?tenant_organisation_id=${tenantOrganisationId}`,
    )
    .then((res) => Promise.resolve(res));
}

export function getWorkflowStagesForVehicleTrackingApi(stageId, inputData) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-workflow-stages-for-vehicle-tracking/?stage_id=${stageId}&input_data=${inputData}`,
    )
    .then((res) => Promise.resolve(res));
}

export function getTargetVsActualConfigForWorkflowStageApi(
  workflowStageId,
  vehicleTrackingNumber,
) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-target-vs-actual-config-for-workflow-stage/?workflow_stage_id=${workflowStageId}&vehicle_tracking_number=${vehicleTrackingNumber}`,
    )
    .then((res) => Promise.resolve(res));
}

export function createTargetVsActualLogsApi(data) {
  return api
    .post(
      `${config.test_url}api/vehicle-tracking/create-target-vs-actual-logs/`,
      data,
    )
    .then((res) => Promise.resolve(res));
}

export function getVehicleTrackingListApi(
  tenantOrganisationId,
  vehicleTrackingNumber,
  vehicleNumber,
  poNumber,
  startDate,
  endDate,
) {
  return api
    .get(`${config.test_url}api/vehicle-tracking/get-vehicle-tracking-list/?`, {
      params: {
        tenant_organisation_id: tenantOrganisationId,
        vehicle_tracking_number: vehicleTrackingNumber,
        vehicle_number: vehicleNumber,
        po_number: poNumber,
        start_date: startDate,
        end_date: endDate,
      },
    })
    .then((res) => Promise.resolve(res));
}

export function getNextAvailableWorkflowStagesApi(
  tenantOrganisationId,
  vehicleTrackingNumber,
) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-next-available-workflow-stages/?`,
      {
        params: {
          tenant_organisation_id: tenantOrganisationId,
          vehicle_tracking_number: vehicleTrackingNumber,
        },
      },
    )
    .then((res) => Promise.resolve(res));
}

export function skipAndMoveVtToAnotherWsApi(
  tenantOrganisationId,
  vehicleTrackingNumber,
  requestedWorkflowStageId,
) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/skip-and-move-vt-to-another-ws/?`,
      {
        params: {
          tenant_organisation_id: tenantOrganisationId,
          vehicle_tracking_number: vehicleTrackingNumber,
          requested_workflow_stage_id: requestedWorkflowStageId,
        },
      },
    )
    .then((res) => Promise.resolve(res));
}

export function getVarianceReportDataApi(
  tenantOrganisationId,
  vehicleTrackingNumber,
  purchaseOrderNumber,
  materialCode,
  startDate,
  endDate,
) {
  return api
    .get(`${config.test_url}api/vehicle-tracking/get-variance-report-data/?`, {
      params: {
        tenant_organisation_id: tenantOrganisationId,
        vehicle_tracking_number: vehicleTrackingNumber,
        purchase_order_number: purchaseOrderNumber,
        material_code: materialCode,
        start_date: startDate,
        end_date: endDate,
      },
    })
    .then((res) => Promise.resolve(res));
}

export function getAvailableMaterialsCodesForOrganisationApi(
  tenantOrganisationId,
) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-available-material-codes/?tenant_organisation_id=${tenantOrganisationId}`,
    )
    .then((res) => Promise.resolve(res));
}
