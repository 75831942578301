export const randomColorPicker = () => {
  const predefinedColors = [
    '#d98880',
    '#f1948a',
    '#c39bd3',
    '#7fb3d5',
    '#76d7c4',
    '#7dcea0',
    '#82e0aa',
    '#f7dc6f',
    '#f8c471',
    '#f0b27a',
    '#e59866',
    '#bfc9ca',
    '#85929e',
    '#e6b0aa',
    '#d2b4de',
    '#a2d9ce',
    '#a9cce3',
    '#f9a95d',
    '#c7d3d4',
    '#d4a1a1',
  ];
  const randomIndex = Math.floor(Math.random() * predefinedColors.length);
  return predefinedColors[randomIndex];
};
