import config from 'src/config/config';
import axios from 'axios';

export async function LoginApi(UserData) {
  return axios
    .post(`${config.test_url}api/vehicle-tracking/login/`, UserData)
    .then((res) => {
      const response = res.data;
      if (response) {
        localStorage.setItem('userData', JSON.stringify(response.user_details));
        localStorage.setItem('token', response.token);
        localStorage.setItem('superAdmin', response.super_admin);
        localStorage.setItem('organisationAdmin', response.organisation_admin);
        localStorage.setItem('normalUser', response.normal_user);
        localStorage.setItem(
          'showVehicleTrackingCreate',
          response.first_stage_assigned,
        );
      }
      return Promise.resolve(response);
    });
}

export async function LogoutApi() {
  localStorage.removeItem('userData');
  localStorage.removeItem('token');
  localStorage.removeItem('superAdmin');
  localStorage.removeItem('organisationAdmin');
  localStorage.removeItem('normalUser');
  localStorage.removeItem('showVehicleTrackingCreate');
  return true;
}
