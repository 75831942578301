import { styled } from '@mui/material/styles';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

export const DrawerHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(2, 2.0),
  minHeight: '20px',
  ...theme.mixins.toolbar,
}));

export const drawerWidth = 270;

export const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: '1px solid rgba(145, 158, 171, 0.30)',
  zIndex: '1',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  display: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
    width: '8px',
    backgroundColor: '#ccc',
  },
  '&:hover::-webkit-scrollbar': {
    display: 'block',
  },
  '&::-webkit-scrollbar-button': {
    background: '#ccc',
    height: '0',
  },
  '&::-webkit-scrollbar-track': {
    background: '#ccc',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#aaa',
    borderRadius: '8px',
  },
  background: '#FAFCFD',
  overflowX: 'hidden',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
});

export const closedMixin = (theme) => ({
  zIndex: '100',
  borderRight: '1px solid rgba(145, 158, 171, 0.30)',
  '&::-webkit-scrollbar': {
    display: 'none',
    width: '8px',
    backgroundColor: '#ccc',
  },
  '&:hover::-webkit-scrollbar': {
    display: 'block',
  },
  '&::-webkit-scrollbar-button': {
    background: '#ccc',
    height: '0',
  },
  '&::-webkit-scrollbar-track': {
    background: '#ccc',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#aaa',
    borderRadius: '8px',
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: '#FAFCFD',
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
    display: 'block',
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
    background: 'red',
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const styledButton = () => ({
  position: 'fixed',
  border: '1px solid rgba(145, 158, 171, 0.30)',
  background: 'white',
  margin: '35px 0px 0px 0px',
  padding: '0.5px',
  '&:hover': {
    backgroundColor: 'white',
  },
});

export const StyledOpenIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'open',
  name: 'StyledIconButton',
})(({ open }) => ({
  left: '55px',
  ...styledButton(),
  '& .MuiButtonBase-root': styledButton(),
  ...(open && {
    display: 'none',
  }),
}));

export const StyledCloseIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'open',
  name: 'StyledCloseIconButton',
})(({ open }) => ({
  left: '260px',
  ...styledButton(),
  '& .MuiButtonBase-root': styledButton(),
  ...(!open && {
    display: 'none',
  }),
}));

export const StyledImageBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
  name: 'StyledImageBox',
})(({ open }) => ({
  ...(open && { display: 'flex' }),
}));

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'open',
  name: 'StyledListItemButton',
})(({ open }) => ({
  display: open ? 'block' : 'none',
  fontSize: '14px',
}));

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'open',
  name: 'StyledListItemButton',
})(({ open }) => ({
  minHeight: 48,
  display: 'flex',
  justifyContent: open ? 'flex-start' : 'center',
  flexDirection: open ? 'row' : 'column',
  margin: open ? '5px 8px' : '5px 8px',
  borderRadius: open ? '5px' : '5px',
  fontSize: open ? '18px' : '18px',
  padding: open ? '15px' : '3px',
}));

export const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'open',
  name: 'StyledListItemButton',
})(({ open }) => ({
  minWidth: 0,
  justifyContent: 'center',
  padding: open ? '0px 20px 0px 0px' : '5px 0px 5px 0px',
}));

export const StyledListItemIconMobile = styled(ListItemIcon, {
  name: 'StyledListItemButtonMobile',
})(() => ({
  minWidth: 0,
  justifyContent: 'center',
  padding: '10px 20px 10px 0px',
}));

export const StyledListItemButtonMobile = styled(ListItemButton, {
  name: 'StyledListItemButtonMobile',
})(() => ({
  minHeight: 48,
  padding: '0px 15px 0px 15px',
  justifyContent: 'center',
  margin: '10px',
  borderRadius: '10px',
}));

export const StyledIcon = () => ({
  fontSize: '16px',
});

export const IconLeft = styled(ChevronLeft, {
  name: 'IconLeft',
})(() => ({
  ...StyledIcon(),
}));

export const IconRight = styled(ChevronRight, {
  name: 'IconRight',
})(() => ({
  ...StyledIcon(),
}));

export const StyledListItem = styled(ListItem, {
  name: 'IconRight',
})(() => ({
  display: 'block',
}));
