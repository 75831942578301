import React from 'react';
import IconButton from '@mui/material/IconButton';
import AccountPopover from 'src/components/Layout/AccountPopover';
import MobileSidebar from 'src/components/MobileSidebar';
import 'src/style/sidebar.css';
import { useMediaQuery } from '@mui/material';

function Navbar() {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <div className={isMobile ? 'div-space-between' : 'div-flex-end'}>
      {isMobile && (
        <IconButton>
          <MobileSidebar />
        </IconButton>
      )}
      <AccountPopover />
    </div>
  );
}

export default Navbar;
