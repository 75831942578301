import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RxDashboard, RxFileText } from 'react-icons/rx';
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';
import { BsBuildings, BsModem } from 'react-icons/bs';
import { VscOrganization } from 'react-icons/vsc';
import { PiTruckDuotone, PiCalculator } from 'react-icons/pi';
import { GiBusStop } from 'react-icons/gi';

const SidebarData = () => {
  const AuthReducer = useSelector((state) => state.AuthReducer);

  const Sidebar = [
    {
      id: 1,
      name: 'Tenant Dashboard',
      url: 'dashboard/',
      icon: <RxDashboard size="18px" />,
    },
    {
      id: 2,
      name: 'Manage Tenants',
      url: 'tenant-management/',
      icon: <HiOutlineBuildingOffice2 size="18px" />,
    },
    {
      id: 3,
      name: 'Manage Organisations',
      url: 'organisation-management/',
      icon: <BsBuildings size="18px" />,
    },
    {
      id: 4,
      name: 'Manage Users',
      url: 'users/',
      icon: <VscOrganization size="18px" />,
    },
    {
      id: 5,
      name: 'Stages',
      url: 'stage-management/',
      icon: <BsModem size="18px" />,
    },
    {
      id: 6,
      name: 'Create Vehicle Tracking',
      url: 'vehicle-tracking/create',
      icon: <PiTruckDuotone size="18px" />,
    },
    {
      id: 7,
      name: 'Station Dashboard',
      url: `stations/`,
      icon: <GiBusStop size="18px" />,
    },
    {
      id: 8,
      name: 'Vehicle Tracking Report',
      url: `report/vehicle-trackings/`,
      icon: <RxFileText size="18px" />,
    },
    {
      id: 9,
      name: 'Excess Shortage Report',
      url: 'report/variance/',
      icon: <PiCalculator size="18px" />,
    },
  ];

  const { superAdmin, organisationAdmin, normalUser, firstStageAssigned } =
    AuthReducer;

  const [filteredSidebarData, setFilteredSidebarData] = useState([]);

  useEffect(() => {
    const filteredData = filterSidebarData();
    setFilteredSidebarData(filteredData);
  }, [superAdmin, organisationAdmin]);

  const filterSidebarData = () => {
    if (superAdmin === true) {
      return Sidebar.filter(
        (item) =>
          item.id === 1 || item.id === 2 || item.id === 3 || item.id === 4,
      );
    }
    if (organisationAdmin === true) {
      return Sidebar.filter(
        (item) =>
          item.id === 1 ||
          item.id === 3 ||
          item.id === 4 ||
          item.id === 5 ||
          item.id === 6 ||
          item.id === 7 ||
          item.id === 8 ||
          item.id === 9,
      );
    }
    if (normalUser === true) {
      if (firstStageAssigned === true) {
        return Sidebar.filter((item) => item.id === 6 || item.id === 7);
      }
      return Sidebar.filter((item) => item.id === 7);
    }
    return null;
  };

  return filteredSidebarData;
};

export default SidebarData;
