import React, { useEffect } from 'react';
import 'src/style/targetvsactual.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTimelineData } from 'src/redux/Actions/VehicleTracking';

function TargetVsActualTimeline() {
  const dispatch = useDispatch();
  const { vehicleTrackingNumber } = useParams();
  const authReducer = useSelector((state) => state.AuthReducer);
  const organisationId = authReducer.userData.organisations?.id;
  useEffect(() => {
    dispatch(getTimelineData(vehicleTrackingNumber, organisationId));
  }, []);
  const vehicleTrackingDataReducer = useSelector(
    (state) => state?.VehicleTrackingReducer,
  );
  const timelineData = vehicleTrackingDataReducer?.timeline;
  return (
    <div className="div-flex-column rg-10">
      <div className="page-container">
        <div className="div-flex-row target-vs-actual-form-header div-space-between">
          <span className="text-xxl text-bold">Timeline</span>
        </div>
      </div>
      <div className="timeline-container">
        <ul className="timeline-list-container">
          {timelineData?.map((logData) => {
            let className = 'timeline-progress';
            if (logData?.logs === null) {
              className = 'timeline-progress';
            } else if (
              logData?.logs?.entry_timestamp &&
              logData?.logs?.exit_timestamp &&
              !logData?.skipped
            ) {
              className = 'timeline-progress is-complete';
            } else if (
              (logData?.logs?.entry_timestamp ||
                logData?.logs?.exit_timestamp) &&
              !logData?.skipped
            ) {
              className = 'timeline-progress is-pending';
            } else if (logData?.skipped) {
              className = 'timeline-progress is-skipped';
            }
            return (
              <li className={className} key={logData?.workflow_stage_id}>
                <div className="timeline-text">
                  <div className="div-flex-row cg-10 div-align-center">
                    <span className="text-lg text-bold">
                      {logData?.workflow_stage_name}
                    </span>
                    {logData?.skipped && (
                      <div className="red-pill bg-red">
                        <span className="text-md">Skipped</span>
                      </div>
                    )}
                  </div>
                  {logData?.logs?.entry_timestamp ? (
                    <span className="text-sm">
                      Entry : {logData?.logs?.entry_timestamp}
                    </span>
                  ) : (
                    <span />
                  )}
                  <br />
                  {logData?.logs?.exit_timestamp ? (
                    <span className="text-sm">
                      Exit : {logData?.logs?.exit_timestamp}
                    </span>
                  ) : (
                    <span />
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default TargetVsActualTimeline;
