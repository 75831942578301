import { combineReducers } from 'redux';
import GlobalErrorReducer from 'src/redux/Reducers/GlobalErrorReducer';
import AuthReducer from 'src/redux/Reducers/AuthReducer';
import TenantReducer from 'src/redux/Reducers/TenantReducer';
import OrganisationReducer from 'src/redux/Reducers/OrganisationReducer';
import VehicleTrackingReducer from 'src/redux/Reducers/VehicleTrackingReducer';

const RootReducer = combineReducers({
  AuthReducer,
  GlobalErrorReducer,
  TenantReducer,
  OrganisationReducer,
  VehicleTrackingReducer,
});
export default RootReducer;
