import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createTargetVsActualLogs,
  getTargetVsActualForConfig,
} from 'src/redux/Actions/VehicleTracking';
import 'src/style/targetvsactual.css';
import { useParams } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { Table } from 'react-bootstrap';

export default function TargetVsActualForm({
  selectedTargetVsActualConfig,
  toggleForm,
}) {
  const dispatch = useDispatch();
  const { workflowStageId, vehicleTrackingNumber } = useParams();

  const vehicleTrackingDataReducer = useSelector(
    (state) => state.VehicleTrackingReducer,
  );

  const targetVsActualData = vehicleTrackingDataReducer?.targetVsActualItems;

  useEffect(() => {
    dispatch(
      getTargetVsActualForConfig(
        vehicleTrackingNumber,
        selectedTargetVsActualConfig.id,
      ),
    );
  }, [dispatch]);

  const authReducer = useSelector((state) => state.AuthReducer);
  const organisationId = authReducer.userData.organisations?.id;

  const [captureWeight, setCaptureWeight] = useState(false);
  const [captureWeightButtonAnimation, setCaptureWeightButtonAnimation] =
    useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const items = [];
    vehicleTrackingDataReducer.targetVsActualItems?.forEach((item) => {
      const actualValue = document.getElementsByName(`actual_${item.id}`)[0]
        ?.value;
      const remarkValue = document.getElementsByName(`remark_${item.id}`)[0]
        ?.value;
      if (actualValue !== '') {
        items.push({
          object_id: item.id,
          target_value: item.target_value,
          actual_value: actualValue,
          name: item.name,
          remarks: remarkValue,
        });
      }
    });
    const data = {
      tenant_organisation_id: organisationId,
      workflow_stage_id: workflowStageId,
      config_id: selectedTargetVsActualConfig.id,
      vehicle_tracking_number: vehicleTrackingNumber,
      table_name: selectedTargetVsActualConfig.table_name,
      field_name: selectedTargetVsActualConfig.field_name,
      target_vs_actual_items: items,
    };

    dispatch(createTargetVsActualLogs(data)).then(() => {
      window.location.reload();
    });
  };

  useEffect(() => {
    let attemptCounter = 0;
    if (captureWeight) {
      const checkTargetVsActual = () => {
        dispatch(
          getTargetVsActualForConfig(
            vehicleTrackingNumber,
            selectedTargetVsActualConfig.id,
          ),
        ).then((data) => {
          const hasNullTargetValue = data.some(
            (item) => item.target_value === null,
          );

          if (hasNullTargetValue) {
            setCaptureWeightButtonAnimation(true);
            if (attemptCounter < 5) {
              // snooze for 5 seconds
              attemptCounter += 1;
              setTimeout(checkTargetVsActual, 5000);
            } else {
              // max limit reached
              setCaptureWeightButtonAnimation(false);
            }
          } else {
            setCaptureWeight(false);
            setCaptureWeightButtonAnimation(false);
          }
        });
      };

      // if capture weight is true, call the function
      checkTargetVsActual();
    }

    return () => {
      // Cleanup on unmount or dependency change
      setCaptureWeight(false);
    };
  }, [
    captureWeight,
    targetVsActualData,
    dispatch,
    vehicleTrackingNumber,
    selectedTargetVsActualConfig.id,
  ]);

  const handleCaptureWeight = (e) => {
    e.preventDefault();
    setCaptureWeight(true);
    setCaptureWeightButtonAnimation(true);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="div-flex-column right-form-layout div-space-between"
    >
      <div>
        <div>
          <div className="div-flex-row target-vs-actual-form-header page-container div-space-between">
            <span className="text-xl text-bold">
              {selectedTargetVsActualConfig.title}
            </span>
            <MdClose size="18px" onClick={toggleForm} />
          </div>
        </div>
        <div className="div-flex-column rg-10 page-container">
          <div className="text-md div-flex-column">
            <label htmlFor="table" className="text-md">
              Assigned Table
            </label>
            <input
              name="status"
              type="text"
              className="form-input-div cursor-not-allowed"
              disabled
              value={selectedTargetVsActualConfig.table_name}
            />
          </div>
          <div className="text-md div-flex-column">
            <label htmlFor="field" className="text-md">
              Assigned Field
            </label>
            <input
              name="status"
              type="text"
              className="form-input-div cursor-not-allowed"
              disabled
              value={selectedTargetVsActualConfig.field_name}
            />
          </div>
          <div className="po-table">
            <Table hover size="lg" className="table-cell-record">
              <thead>
                <tr>
                  <th className="bg-grey form-table-width-30">
                    <div className="text-lg text-bold">ID</div>
                  </th>
                  <th className="bg-grey form-table-width-90">
                    <div className="text-lg text-bold">Name</div>
                  </th>
                  <th className="bg-grey form-table-width-90">
                    <div className="text-lg text-bold">Target</div>
                  </th>
                  <th className="bg-grey">
                    <div className="text-lg text-bold">Actual</div>
                  </th>
                  <th className="bg-grey">
                    <div className="text-lg text-bold">Remark</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {targetVsActualData?.map((actualData) => (
                  <tr key={actualData?.id}>
                    <td className="form-table-width-30">
                      <div className="text-md">{actualData?.id}</div>
                    </td>
                    <td className="form-table-width-90">
                      <div className="text-md">{actualData?.name}</div>
                    </td>
                    <td className="form-table-width-90">
                      <div className="text-md">{actualData?.target_value}</div>
                    </td>
                    <td>
                      <div className="text-md">
                        <input
                          name={`actual_${actualData.id}`}
                          type="text"
                          className="modal-form-input"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="text-md">
                        <input
                          name={`remark_${actualData.id}`}
                          type="text"
                          className="modal-form-input"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className="div-flex-end cg-10 ">
        {!captureWeightButtonAnimation ? (
          <button className="btn-black" onClick={handleCaptureWeight}>
            Capture Weight
          </button>
        ) : (
          <div className="div-flex-row cg-5 div-align-center">
            <span className="text-sm text-bold">Capturing Weight</span>
            <div className="capture-weight-animation">
              <span className="capture-weight-loading-dot"></span>
              <span className="capture-weight-loading-dot"></span>
              <span className="capture-weight-loading-dot"></span>
              <span className="capture-weight-loading-dot"></span>
            </div>
          </div>
        )}
        <button className="btn-black" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
}
