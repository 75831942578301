import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from 'src/components/Sidebar';
import Box from '@mui/material/Box';

function Layout() {
  const token = localStorage.getItem('token');

  return (
    <Box sx={{ display: 'flex' }}>
      {token ? (
        <>
          <Sidebar />
          <Outlet />
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </Box>
  );
}

export default Layout;
