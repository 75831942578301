import config from 'src/config/config';
import api from 'src/api/interceptor';

export function getTenantListApi() {
  return api
    .get(`${config.test_url}api/tenant/get-tenants/`)
    .then((res) => Promise.resolve(res));
}

export function createTenantApi(data) {
  return api
    .post(`${config.test_url}api/tenant/create/`, data)
    .then((res) => Promise.resolve(res));
}

export function getUsersForTenantApi(data) {
  return api
    .get(
      `${config.test_url}api/vehicle-tracking/get-profiles-for-tenant/?tenant_id=${data}`,
    )
    .then((res) => Promise.resolve(res));
}

export function getAllUsersApi() {
  return api
    .get(`${config.test_url}api/vehicle-tracking/get-profiles-for-tenant/`)
    .then((res) => Promise.resolve(res));
}

export function updateUserProfileApi(data) {
  return api
    .post(
      `${config.test_url}api/vehicle-tracking/update-user-type-and-stage-assignment/`,
      data,
    )
    .then((res) => Promise.resolve(res));
}

export function createUserApi(data) {
  return api
    .post(`${config.test_url}api/core/create-user/ `, data)
    .then((res) => Promise.resolve(res));
}
