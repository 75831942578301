import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { randomColorPicker } from 'src/utils/RandomColorPicker';
import Spinner from 'react-bootstrap/Spinner';
import { renderToString } from 'react-dom/server';

function WaitingTimeChart({ stages, time }) {
  const color = useMemo(() => randomColorPicker(), []);

  if (!stages || !time || stages.length === 0 || time.length === 0) {
    return (
      <div className="div-align-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const options = {
    chart: {
      toolbar: { show: false },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      colors: [color],
    },
    tooltip: {
      enabled: true,
      custom({ dataPointIndex }) {
        const isSkipped = time[dataPointIndex].skipped;
        const stage = stages[dataPointIndex];
        return renderToString(
          <div className="text-sm">
            <div className="d-flex bg-grey p-2">
              <span>{stage}</span>
              {isSkipped ? (
                <div className="ms-1">
                  <span className="red-pill bg-red">Skipped</span>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="p-2 text-bold">
              <span>Entry: {time[dataPointIndex].entry_time}</span>
              <br />
              {isSkipped ? (
                ''
              ) : (
                <>
                  <span>Exit: {time[dataPointIndex].exit_time}</span>
                  <br />
                  <span>
                    Total: {time[dataPointIndex].total_minutes_humanized}
                  </span>
                  <br />
                </>
              )}
            </div>
          </div>,
        );
      },
      marker: { show: true },
    },
    fill: { colors: [color] },
    grid: {
      borderColor: '#e0e0e0',
      strokeDashArray: 4,
    },
    xaxis: {
      categories: stages,
      labels: {
        show: true,
        style: { fontSize: '12px', fontWeight: 'bold', colors: '#333' },
      },
      axisBorder: { show: true, color: '#888' },
      axisTicks: { show: true, color: '#888' },
    },
    yaxis: {
      labels: {
        show: true,
        style: { fontSize: '12px', fontWeight: 'bold', colors: '#333' },
      },
    },
    markers: {
      size: 4,
      colors: [color],
      strokeWidth: 2,
      hover: { size: 6 },
    },
  };
  const series = [
    {
      name: 'Total',
      data: [...time.map((item) => item.total_minutes)],
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="line"
      width="100%"
      height="300px"
    />
  );
}

export default WaitingTimeChart;
