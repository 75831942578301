import React from 'react';
import { MdClose } from 'react-icons/md';

export default function OrganisationDetail({ Organisation, onClose }) {
  return (
    <div className="tenant-detail-right-container">
      <div className="div-flex-column">
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xxxl text-bold">Organisation Details</span>
          <MdClose size="18px" onClick={onClose} />
        </div>
        <span className="text-md text-400">
          Organisation #{Organisation?.id}
        </span>
      </div>
      <div className="div-flex-column rg-10">
        <div className="div-flex-column">
          <span className="text-md text-bold">Name-</span>
          <span className="text-md text-400">{Organisation?.name}</span>
        </div>
        <div className="div-flex-column">
          <span className="text-md text-bold">GST Number-</span>
          <span className="text-md text-400">{Organisation?.gst_number}</span>
        </div>
        <div className="div-flex-column">
          <span className="text-md text-bold">Description-</span>
          <span className="text-md text-400">{Organisation?.description}</span>
        </div>
      </div>
    </div>
  );
}
