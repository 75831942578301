import React, { useState } from 'react';
import CreateVehicleTrackingMainComponent from 'src/components/VehicleEntry/CreateVehicleTrackingMainComponent';
import ThreeColLayout from 'src/components/Layout/ThreeColLayout';
import CreateVehicleTrackingLeftSidebar from 'src/components/VehicleEntry/CreateVehicleTrackingLeftSidebar';
import CreateVehicleTrackingRightSidebar from 'src/components/VehicleEntry/CreateVehicleTrackingRightSidebar';
import CreateVehicleTrackingHeader from 'src/components/VehicleEntry/CreateVehicleTrackingHeader';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createVehicleTracking } from 'src/redux/Actions/VehicleTracking';

export default function CreateVehicleTracking() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.AuthReducer);
  const organisationId = authReducer.userData.organisations?.id;
  const [leftSidebar, setLeftSidebar] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [vehicleTrackingData, setVehicleTrackingData] = useState({
    selectedPurchaseOrder: [],
    submitPurchaseOrderDetails: [],
  });

  const handleChange = (name, value) => {
    setVehicleTrackingData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleVehicleTrackingCreate = (e) => {
    e.preventDefault();
    const poListWithAdditionalDetails = [];
    vehicleTrackingData.selectedPurchaseOrder?.forEach((poID) => {
      const ewayBill = document.getElementById(`eway_bill_${poID}`)?.value;
      const invoiceNumber = document.getElementById(`invoice_${poID}`)?.value;
      poListWithAdditionalDetails.push({
        po_id: poID,
        eway_bill_number: ewayBill,
        invoice_number: invoiceNumber,
      });
    });
    const createGateEntryPayload = {
      vehicle_number: e.target.vehicleNumber.value,
      po: poListWithAdditionalDetails,
      tenant_organisation_id: organisationId,
      driver_name: e.target.driverName.value,
      driving_license_number: e.target.driverLicenseNumber.value,
      safety_helmet: e.target.safetyHelmet.checked,
      safety_shoes: e.target.safetyShoes.checked,
      trem_card: e.target.tremCard.checked,
      safety_handgloves: e.target.safetyHandgloves.checked,
      safety_goggles: e.target.safetyGoggles.checked,
      driver_mobile_number: e.target.driverMobileNumber.value,
      driver_license_validity: e.target.driverLicenseValidity.value || null,
      puc_valid_upto: e.target.pucValidUpto.value || null,
      container_number: e.target.containerNumber.value,
      tank_condition: e.target.tankCondition.value,
      information_panel: e.target.informationPanel.value,
      tyre_condition: e.target.tyreCondition.value,
      lid_condition: e.target.lidCondition.value,
      reflectors: e.target.reflectors.checked,
      test_report: e.target.coa.value,
      weight_slip: e.target.weightSlip.value,
      remarks: e.target.remarks.value,
      workflow_id: e.target.workflow.value,
    };
    dispatch(createVehicleTracking(createGateEntryPayload)).then((response) => {
      const vehicleTrackingNumber = response?.vehicleTrackingNumber;
      if (vehicleTrackingNumber) {
        navigate(`/stations/`);
      }
    });
  };

  const toggleLeftLayout = () => {
    setLeftSidebar(!leftSidebar);
  };

  const toggleRightLayout = () => {
    setRightSidebar(!rightSidebar);
  };

  const calculateColumnSizes = () => {
    if (leftSidebar && rightSidebar) {
      return { leftColSize: 4, middleColSize: 6, rightColSize: 2 };
    }
    if (leftSidebar) {
      return { leftColSize: 6, middleColSize: 6, rightColSize: 0 };
    }
    if (rightSidebar) {
      return { leftColSize: 0, middleColSize: 10, rightColSize: 2 };
    }
    return { leftColSize: 0, middleColSize: 12, rightColSize: 0 };
  };

  const { leftColSize, middleColSize, rightColSize } = calculateColumnSizes();

  return (
    <form onSubmit={handleVehicleTrackingCreate}>
      <ThreeColLayout
        showLeftSidebar={leftSidebar}
        showMiddleContainer
        showRightSidebar={rightSidebar}
        showHeader
        headerContent={<CreateVehicleTrackingHeader />}
        leftSidebarContent={<CreateVehicleTrackingLeftSidebar />}
        middleContainerContent={
          <CreateVehicleTrackingMainComponent
            setSelectedPurchaseOrder={(value) =>
              handleChange('selectedPurchaseOrder', value)
            }
            selectedPurchaseOrder={vehicleTrackingData.selectedPurchaseOrder}
            setSubmitPurchaseOrderDetails={(value) =>
              handleChange('submitPurchaseOrderDetails', value)
            }
          />
        }
        rightSidebarContent={<CreateVehicleTrackingRightSidebar />}
        showFooter
        onToggleLeftLayout={toggleLeftLayout}
        onToggleRightLayout={toggleRightLayout}
        leftColSize={leftColSize}
        middleColSize={middleColSize}
        rightColSize={rightColSize}
      />
    </form>
  );
}
