import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PiTruckDuotone } from 'react-icons/pi';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  getWorkflowStagesForVehicleTracking,
  clearWorkflowStagesForVehicleTracking,
} from 'src/redux/Actions/VehicleTracking';

export default function StageRightSidebar({
  toggleRightSidebarTable,
  selectedStageId,
}) {
  const dispatch = useDispatch();

  const vehicleTrackingDataReducer = useSelector(
    (state) => state.VehicleTrackingReducer,
  );

  const { stationData } = vehicleTrackingDataReducer;
  const navigate = useNavigate();
  const handleNavigation = (inputData) => {
    if (inputData !== null && inputData !== '') {
      dispatch(getWorkflowStagesForVehicleTracking(selectedStageId, inputData));
    }
  };

  const [searchText, setSearchText] = useState('');

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const workflowStages =
    vehicleTrackingDataReducer.workflowStagesForVehicleTracking?.workflowStages;
  useEffect(() => {
    dispatch(clearWorkflowStagesForVehicleTracking());
  }, [dispatch, selectedStageId]);

  const renderWorkflowStages = (stages) => {
    let encounteredPending = false;

    return stages.map((stage) => {
      const disableButton = encounteredPending;

      // If current stage is "Pending", set the flag to disable subsequent buttons
      if (stage.workflow_status === 'Pending') {
        encounteredPending = true;
      }

      return (
        <div
          key={stage.workflow_stage_id}
          className="d-flex justify-content-between align-items-center p-2 border rounded mb-3"
        >
          <div>
            <span>{stage.workflow_stage_name}&nbsp;</span>
            <span className="bg-blue blue-pill text-md">
              {stage.workflow_status}
            </span>
          </div>
          <button
            className="btn btn-sm btn-dark"
            onClick={() => navigate(`/${stage.url}`)}
            disabled={disableButton}
          >
            Go to Stage
          </button>
        </div>
      );
    });
  };

  return (
    <div className="div-flex-column rg-10">
      <div className="div-flex-row div-space-between div-align-center">
        <span className="text-xxxl text-bold">Scan Incoming Vehicles</span>
        <MdClose size="18px" onClick={toggleRightSidebarTable} />
      </div>
      <div className="div-flex-row cg-20">
        <div className="div-flex-column rg-10">
          <input
            className="modal-form-input"
            type="text"
            name="search"
            value={searchText}
            onChange={handleChange}
            required
          />
          <button
            className="btn-black"
            type="submit"
            onClick={() => handleNavigation(searchText)}
          >
            Get Stages
          </button>
          {workflowStages?.length > 0
            ? renderWorkflowStages(workflowStages)
            : null}
        </div>
      </div>
      <hr />
      <div className="div-flex-column rg-10 div-scrollable-details history-div-height">
        <div className="div-flex-column">
          <span className="text-xl text-bold">History</span>
          <span className="text-md text-400">
            {stationData.completedVehicleTracking?.count} Vehicles
          </span>
        </div>
        <div className="div-flex-column rg-10">
          {stationData.completedVehicleTracking?.count > 0 ? (
            stationData.completedVehicleTracking?.data.map((data) => (
              <div
                key={data.vehicle_tracking_number}
                className="div-flex-column stage-entry-card"
              >
                <div className="div-flex-row cg-10 div-align-center">
                  <div className="stage-icon-outline bg-green">
                    <PiTruckDuotone size="24px" />
                  </div>
                  <div className="div-flex-column">
                    <span className="text-md text-400">
                      {data.vehicle_tracking_number}
                    </span>
                    <span className="text-md text-400">
                      {data.vehicle_number}
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <span className="text-md text-bold">No Entries</span>
          )}
        </div>
      </div>
    </div>
  );
}
