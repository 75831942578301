import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getVehicleTrackingList } from 'src/redux/Actions/VehicleTracking';
import 'src/style/vehicleTrackingList.css';

export default function VehicleTrackingListMainContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const vehicleTrackingDataReducer = useSelector(
    (state) => state.VehicleTrackingReducer,
  );

  const authReducer = useSelector((state) => state.AuthReducer);
  const organisationId = authReducer.userData.organisations?.id;

  const { vehicleTrackingList } = vehicleTrackingDataReducer;

  function handleSearch() {
    const vehicleTrackingNumber = document.getElementById(
      'vehicleTrackingNumber',
    ).value;
    const vehicleNumber = document.getElementById('vehicleNumber').value;
    const poNumber = document.getElementById('poNumber').value;
    const startDate = document.getElementById('startDate').value;
    const endDate = document.getElementById('endDate').value;

    dispatch(
      getVehicleTrackingList(
        organisationId,
        vehicleTrackingNumber,
        vehicleNumber,
        poNumber,
        startDate,
        endDate,
      ),
    );
  }

  function openVehicleTrackingReport(vehicleTrackingNumber) {
    navigate(`/report/${vehicleTrackingNumber}`);
  }

  return (
    <div className="page-layout">
      <div className="div-flex-column">
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xxxl text-bold">Vehicle Tracking</span>
        </div>
        <hr />
      </div>

      <div className="div-flex-column rg-20">
        <div className="row gx-3">
          {/* Vehicle Tracking Number */}
          <div className="col-12 col-md-4 col-auto mt-1">
            <div className="search-container">
              <input
                id="vehicleTrackingNumber"
                className="report-search-bar text-md w-100"
                type="text"
                placeholder="Vehicle Tracking Number"
              />
            </div>
          </div>

          {/* Vehicle Number */}
          <div className="col-12 col-md-2 col-auto mt-1">
            <div className="search-container">
              <input
                id="vehicleNumber"
                className="report-search-bar text-md w-100"
                type="text"
                placeholder="Vehicle Number"
              />
            </div>
          </div>

          {/* PO Number */}
          <div className="col-12 col-md-auto mt-1">
            <div className="search-container">
              <input
                id="poNumber"
                className="report-search-bar text-md w-100"
                type="text"
                placeholder="PO Number"
              />
            </div>
          </div>

          {/* Start Date */}
          <div className="col-12 col-md-auto mt-1">
            <div className="search-container">
              <input
                id="startDate"
                className="report-search-bar text-md w-100"
                type="date"
                defaultValue={new Date().toISOString().slice(0, 10)} // Default to today's date
              />
            </div>
          </div>

          {/* End Date */}
          <div className="col-12 col-md-auto mt-1">
            <div className="search-container">
              <input
                id="endDate"
                className="report-search-bar text-md w-100"
                type="date"
                defaultValue={new Date().toISOString().slice(0, 10)} // Default to today's date
              />
            </div>
          </div>

          {/* Search Button */}
          <div className="d-flex col-12 col-md-auto mt-1">
            <button className="btn-black" onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>

        <div className="table-wrapper">
          <Table hover responsive size="lg" className="table-cell-record">
            <thead>
              <tr>
                <th colSpan={2} className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-100">
                    Tracking Number
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-100">
                    Vehicle Number
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-100">
                    PO Number
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-100">
                    Current Stage
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-100">
                    Entry Time
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-100">
                    Exit Time
                  </div>
                </th>
                <th className="bg-grey">
                  <div className="text-lg text-bold table-cell-min-width-100">
                    Supplier Name
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="div-scrollable-details">
              {vehicleTrackingList.length > 0 ? (
                vehicleTrackingList.map((vehicleTrackingObject) => (
                  <tr key={vehicleTrackingObject.vehicle_tracking_number}>
                    <td colSpan={2} className="table-cell-min-width-100">
                      <div className="div-flex-row cg-10 div-align-center">
                        <div className="text-md text-400 text-center">
                          <button
                            className="open-report-in-tab"
                            onClick={() =>
                              openVehicleTrackingReport(
                                vehicleTrackingObject.vehicle_tracking_number,
                              )
                            }
                          >
                            {vehicleTrackingObject.vehicle_tracking_number}
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="table-cell-min-width-100">
                      <div className="text-md text-400">
                        {vehicleTrackingObject.vehicle_number}
                      </div>
                    </td>
                    <td className="table-cell-min-width-100">
                      <div className="text-md text-400">
                        {vehicleTrackingObject.po_number.map(
                          (purchaseOrderNumber) => (
                            <span key={purchaseOrderNumber}>
                              {purchaseOrderNumber}
                            </span>
                          ),
                        )}
                      </div>
                    </td>
                    <td className="table-cell-min-width-100">
                      <div className="text-md text-400">
                        {vehicleTrackingObject.current_stage}
                      </div>
                    </td>
                    <td className="table-cell-min-width-100">
                      <div className="text-md text-400">
                        {vehicleTrackingObject.entry_time}
                      </div>
                    </td>
                    <td className="table-cell-min-width-100">
                      <div className="text-md text-400">
                        {vehicleTrackingObject.exit_time}
                      </div>
                    </td>
                    <td className="table-cell-min-width-100">
                      <div className="text-md text-400">
                        {vehicleTrackingObject.supplier_name.map(
                          (supplierName) => (
                            <span key={supplierName}>{supplierName}</span>
                          ),
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="div-flex-row div-align-center">
                  <span className="text-md text-400">No results found</span>
                </div>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
