import React, { useRoutes } from 'react-router-dom';
import Layout from 'src/components/Layout/Layout';
import { ACTIONS } from 'src/utils/roles/actions';
import AccessControl from 'src/components/AccessControl';
import StatusCode403 from 'src/pages/StatusCode/403';
import StatusCode404 from 'src/pages/StatusCode/404';
import Test1 from 'src/pages/Test/Test1';
import Test2 from 'src/pages/Test/Test2';
import Login from 'src/pages/Login';
import TenantManagementLayout from 'src/pages/Tenant/TenantManagementLayout';
import OrganisationManagementLayout from 'src/pages/Tenant/Organisation/OrganisationManagementLayout';
import StagesManagementLayout from 'src/pages/Tenant/Organisation/StagesManagementLayout';
import UserManagementLayout from 'src/pages/Tenant/UserManagementLayout';
import CommonDashboard from 'src/pages/Tenant/CommonDashboard';
import TargetVsActualLayout from 'src/pages/TargetVsActualLayout';
import CreateVehicleTracking from 'src/pages/CreateVehicleTracking';
import VehicleTrackingReport from 'src/pages/VehicleTrackingReport';
import StageDashboard from 'src/pages/StageDashboard';
import VehicleTrackingList from 'src/pages/VehicleTrackingList';
import VarianceReport from 'src/pages/VarianceReport';

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: 'test1/',
          element: (
            <AccessControl
              action={ACTIONS.TEST.VISIT}
              optionalChildren={<StatusCode403 />}
            >
              <Test1 />
            </AccessControl>
          ),
        },
        {
          path: 'test2/',
          element: <Test2 />,
        },
        { path: 'vehicle-tracking/create', element: <CreateVehicleTracking /> },
        { path: 'report/:slug', element: <VehicleTrackingReport /> },
        {
          path: '/:workflowStageId/:vehicleTrackingNumber',
          element: <TargetVsActualLayout />,
        },
        {
          path: 'stations/',
          element: <StageDashboard />,
        },
        { path: 'dashboard/', element: <CommonDashboard /> },
        {
          path: 'tenant-management/',
          element: <TenantManagementLayout />,
        },
        {
          path: 'organisation-management/',
          element: <OrganisationManagementLayout />,
        },
        { path: 'stage-management/', element: <StagesManagementLayout /> },
        { path: 'users/', element: <UserManagementLayout /> },
        {
          path: 'report/',
          children: [
            {
              path: 'variance/',
              element: <VarianceReport />,
            },
            {
              path: 'vehicle-trackings/',
              element: <VehicleTrackingList />,
            },
          ],
        },
      ],
    },
    {
      path: 'login/',
      element: <Login />,
    },
    {
      path: '*',
      element: <StatusCode404 />,
    },
  ]);

  return routes;
}
