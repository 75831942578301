import React from 'react';
import 'src/App.css';

function StatusCode404() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>404</h1>
        <p>Not found</p>
      </header>
    </div>
  );
}

export default StatusCode404;
