import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import 'src/style/tenant.css';
import { useDispatch, useSelector } from 'react-redux';
import { getStages } from 'src/redux/Actions/Organisation';

export default function StageList({ onToggleShowCreateStageForm, StageForm }) {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.AuthReducer);
  const organisationId = authReducer.userData.organisations?.id;
  useEffect(() => {
    dispatch(getStages(organisationId));
  }, [dispatch]);

  const organisationReducer = useSelector((state) => state.OrganisationReducer);
  const userData = organisationReducer?.stagesList;

  return (
    <div className="div-flex-column rg-20">
      <div className="div-flex-column">
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xxxl text-bold">Stage Management</span>
          {!StageForm && (
            <button className="btn-black" onClick={onToggleShowCreateStageForm}>
              Add Stage
            </button>
          )}
        </div>
        <span className="text-md text-400">{userData?.length} Stages</span>
      </div>
      <div className="po-table">
        <Table hover size="lg" className="table-cell-record">
          <thead>
            <tr>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  ID
                </div>
              </th>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  Name
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="div-scrollable-details">
            {userData?.length > 0 ? (
              userData.map((userDataObj) => (
                <tr key={userDataObj.id}>
                  <td className="table-cell-min-width-100">
                    <div className="div-flex-row cg-10 div-align-center">
                      <div className="text-md text-400 text-center">
                        #{userDataObj.id}
                      </div>
                    </div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md text-400">{userDataObj.name}</div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan="6">
                  <div className="text-md text-400">No Stage Created</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
