import React from 'react';

export default function CreateVehicleTrackingRightSidebar() {
  const choices = [
    { id: 1, name: 'Average' },
    { id: 2, name: 'Good' },
    { id: 3, name: 'Excellent' },
  ];

  return (
    <div className="vehicle-entry-component">
      <span className="text-lg text-grey text-bold">CheckList</span>
      <div className="div-flex-column rg-20">
        <div className="div-flex-column rg-5">
          <span className="text-md text-bold">Tank Condition</span>
          <select name="tankCondition" className="modal-form-input">
            <option value="">Select a value</option>
            {choices.map((choice) => (
              <option key={choice.id} value={choice.id}>
                {choice.name}
              </option>
            ))}
          </select>
        </div>
        <div className="div-flex-column">
          <span className="text-md text-bold">Information Panel</span>
          <select name="informationPanel" className="modal-form-input">
            <option value="">Select a value</option>
            {choices.map((choice) => (
              <option key={choice.id} value={choice.id}>
                {choice.name}
              </option>
            ))}
          </select>
        </div>
        <div className="div-flex-column">
          <span className="text-md text-bold">Lid Condition</span>
          <select name="lidCondition" className="modal-form-input">
            <option value="">Select a value</option>
            {choices.map((choice) => (
              <option key={choice.id} value={choice.id}>
                {choice.name}
              </option>
            ))}
          </select>
        </div>
        <div className="div-flex-column">
          <span className="text-md text-bold">Tyre Condition</span>
          <select name="tyreCondition" className="modal-form-input">
            <option value="">Select a value</option>
            {choices.map((choice) => (
              <option key={choice.id} value={choice.id}>
                {choice.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="div-flex-column rg-10">
        <div className="div-flex-row cg-5">
          <input name="tremCard" type="checkbox" />
          <span className="text-md text-bold">TREM Card</span>
        </div>
        <div className="div-flex-row cg-5">
          <input name="reflectors" type="checkbox" />
          <span className="text-md text-bold">Reflectors</span>
        </div>
        <div className="div-flex-row cg-5">
          <input name="safetyHelmet" type="checkbox" />
          <span className="text-md text-bold">Safety Helmet</span>
        </div>
        <div className="div-flex-row cg-5">
          <input name="safetyShoes" type="checkbox" />
          <span className="text-md text-bold">Safety Shoes</span>
        </div>
        <div className="div-flex-row cg-5">
          <input name="safetyHandgloves" type="checkbox" />
          <span className="text-md text-bold">Safety Handgloves</span>
        </div>
        <div className="div-flex-row cg-5">
          <input name="safetyGoggles" type="checkbox" />
          <span className="text-md text-bold">Safety Goggles</span>
        </div>
      </div>
    </div>
  );
}
