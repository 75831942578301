import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { randomColorPicker } from 'src/utils/RandomColorPicker';
import Spinner from 'react-bootstrap/Spinner';

function TargetVsActualChart({ stages, overallSuccess }) {
  const barColor1 = useMemo(() => randomColorPicker(), []);
  const barColor2 = useMemo(() => randomColorPicker(), []);

  if (!stages || !overallSuccess) {
    return (
      <div className="div-align-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const options = {
    chart: {
      toolbar: { show: false },
    },
    tooltip: {
      enabled: true,
      style: { fontSize: '12px' },
    },
    plotOptions: {
      bar: {
        columnWidth: '90%',
        borderRadius: 6,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#2C3E50'],
        fontSize: '14px',
      },
    },
    colors: [barColor1, barColor2],
    xaxis: {
      categories: stages,
      labels: {
        show: true,
        style: { fontSize: '12px', fontWeight: 'bold', colors: '#333' },
      },
      axisBorder: { show: true, color: '#888' },
      axisTicks: { show: true, color: '#888' },
    },
    yaxis: {
      labels: {
        show: true,
        style: { fontSize: '12px', fontWeight: 'bold', colors: '#333' },
      },
    },
    grid: {
      borderColor: '#e0e0e0',
      strokeDashArray: 4,
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
    },
  };

  const series = [
    {
      name: 'Overall Success Rate',
      data: overallSuccess,
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width="100%"
      height="300px"
    />
  );
}

export default TargetVsActualChart;
