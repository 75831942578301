import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from 'src/redux/Actions/Tenant';
import { getStages } from 'src/redux/Actions/Organisation';
import Select, { components } from 'react-select';

function StageListComponent(props) {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} />
        <label className="ms-1">{props.label}</label>
      </components.Option>
    </div>
  );
}

export default function UserDetails({ user, onClose }) {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.AuthReducer);
  const tenantId = authReducer.userData.tenant?.id;
  const isSuperAdmin = authReducer.superAdmin;
  const getUserSelection = (superAdmin) => {
    let data;
    if (superAdmin) {
      data = [
        { id: '1', name: 'Super Admin' },
        { id: '2', name: 'Admin' },
        { id: '3', name: 'User' },
      ];
    } else {
      data = [
        { id: '2', name: 'Admin' },
        { id: '3', name: 'User' },
      ];
    }
    return data;
  };
  const userTypes = getUserSelection(isSuperAdmin);
  const userTypeMap = userTypes.map((userType) => ({
    value: userType.id,
    label: userType.name,
  }));

  const organisationReducer = useSelector((state) => state.OrganisationReducer);

  useEffect(() => {
    dispatch(getStages(tenantId));
  }, [dispatch]);

  const stagesList = organisationReducer?.stagesList;

  const stageMap = stagesList.map((stageData) => ({
    value: stageData.id,
    label: stageData.name,
  }));

  const [selectedStageForUpdate, setSelectedStage] = useState(null);
  const [selectedUserTypeForUpdate, setSelectedUserType] = useState(null);

  const handleStageDropdownChange = (selectedStagesList) => {
    const selectedStageValues = selectedStagesList.map(
      (stageData) => stageData.value,
    );
    setSelectedStage(selectedStageValues);
  };

  const handleUserTypeChange = (selectedUserType) => {
    setSelectedUserType(selectedUserType);
  };

  const handleUserDetailFormSubmit = (e) => {
    e.preventDefault();

    const changedData = {
      profile_id: user.profile_id,
      tenant_organisation_id: tenantId,
      ...(selectedUserTypeForUpdate && {
        user_type: selectedUserTypeForUpdate.value,
      }),
      stage_ids: selectedStageForUpdate,
    };
    dispatch(updateProfile(changedData)).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className="tenant-detail-right-container div-space-between">
      <div className="div-flex-column rg-10">
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xxxl text-bold">User Details</span>
          <MdClose size="18px" onClick={onClose} />
        </div>
        <span className="text-md text-400">User #{user?.profile_id}</span>
        <div className="div-flex-column">
          <span className="text-md text-bold">Name-</span>
          <span className="text-md text-400">{user?.name}</span>
        </div>
        <div className="div-flex-column">
          <span className="text-md text-bold">Email-</span>
          <span className="text-md text-400">{user?.email}</span>
        </div>
        <div className="div-flex-column">
          <span className="text-md text-bold">User Type-</span>
          <span className="text-md text-400">{user?.user_type}</span>
        </div>
        {isSuperAdmin && (
          <div className="div-flex-column">
            <span className="text-md text-bold">Tenant-</span>
            <span className="text-md text-400">{user?.tenant_name}</span>
          </div>
        )}
        <div className="div-flex-column">
          <span className="text-md text-bold">Organisation-</span>
          <span className="text-md text-400">{user?.organisation?.name}</span>
        </div>
        <form
          onSubmit={handleUserDetailFormSubmit}
          className="div-flex-column rg-10 div-space-between submit-form-padding"
        >
          <div className="div-flex-column rg-5">
            <span className="text-md text-bold">Assign Stage:</span>
            <div className="div-flex-row cg-10">
              <Select
                name="assignStage"
                className="modal-form-input"
                options={stageMap}
                onChange={handleStageDropdownChange}
                isMulti
                noOptionsMessage={() => 'Stage not found...'}
                isSearchable
                placeholder="Select Stage..."
                styles={{
                  multiValueRemove: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: '#e3e3e3',
                    color: 'grey',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? '#dedede' : 'inherit',
                    color: 'black',
                  }),
                }}
                components={{
                  Option: StageListComponent,
                }}
                hideSelectedOptions={false}
              />
            </div>
          </div>

          <div className="div-flex-column rg-5">
            <span className="text-md text-bold">Change User Type:</span>
            <div className="div-flex-row cg-10">
              <Select
                name="userType"
                className="modal-form-input"
                options={userTypeMap}
                onChange={handleUserTypeChange}
                noOptionsMessage={() => 'User Type not found...'}
                placeholder="Select User Type"
                isSearchable={false}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? '#dedede' : 'inherit',
                    color: 'black',
                  }),
                }}
              />
            </div>
          </div>

          <div className="div-flex-end">
            <button className="btn-black " type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
