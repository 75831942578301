import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import 'src/style/tenant.css';
import { useSelector, useDispatch } from 'react-redux';
import { TbSquareLetterAFilled, TbSquareLetterEFilled } from 'react-icons/tb';
import { getTenantList } from 'src/redux/Actions/Tenant';

export default function TenantList({
  selectedTenant,
  setSelectedTenant,
  onSelectTenant,
  onDeselectTenant,
  onToggleShowCreateTenantForm,
  tenantForm,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTenantList());
  }, [dispatch]);

  const tenantReducer = useSelector((state) => state.TenantReducer);
  const tenantData = tenantReducer?.tenantList;
  const handleCheckboxChange = (tenantDataObj, isChecked) => {
    if (isChecked) {
      setSelectedTenant(tenantDataObj);
      onSelectTenant(tenantDataObj);
    } else {
      setSelectedTenant(null);
      onDeselectTenant();
    }
  };

  return (
    <div className="div-flex-column rg-20">
      <div className="div-flex-column">
        <div className="div-flex-row div-space-between div-align-center">
          <span className="text-xxxl text-bold">Tenant Management</span>
          {!tenantForm && (
            <button
              className="btn-black"
              onClick={onToggleShowCreateTenantForm}
            >
              Add Tenant
            </button>
          )}
        </div>
        <span className="text-md text-400">{tenantData?.length} Tenants</span>
      </div>
      <div className="po-table">
        <Table hover size="lg" className="table-cell-record">
          <thead>
            <tr>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100 table-id-column-padding">
                  ID
                </div>
              </th>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  Name
                </div>
              </th>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  Start Date
                </div>
              </th>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  End Date
                </div>
              </th>
              <th className="bg-grey">
                <div className="text-lg text-bold table-cell-min-width-100">
                  Domain
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="div-scrollable-details">
            {tenantData?.length > 0 ? (
              tenantData.map((tenantDataObj) => (
                <tr key={tenantDataObj.name}>
                  <td className="table-cell-min-width-100">
                    <div className="div-flex-row cg-10 div-align-center">
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={selectedTenant === tenantDataObj}
                        onChange={(e) =>
                          handleCheckboxChange(tenantDataObj, e.target.checked)
                        }
                      />
                      {tenantDataObj.status === '1' ? (
                        <TbSquareLetterAFilled size="20px" color="#127b1b" />
                      ) : (
                        <TbSquareLetterEFilled size="20px" color="#ce3a3a" />
                      )}
                      <div className="text-md text-400 text-center">
                        #{tenantDataObj.tenant_id}
                      </div>
                    </div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md text-400">{tenantDataObj.name}</div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md text-400">
                      {tenantDataObj.start_date}
                    </div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md text-400">
                      {tenantDataObj.end_date}
                    </div>
                  </td>
                  <td className="table-cell-min-width-100">
                    <div className="text-md text-400">
                      {tenantDataObj.domain}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan="6">
                  <div className="text-md text-400">No Tenant Created</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
